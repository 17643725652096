import React from 'react'
import styles from './styles.module.scss'
import { useHistory } from 'react-router-dom'
import { t } from 'i18next'

const SearchButton = () => {
  const history = useHistory()

  return (
    <button className={styles.searchButton} onClick={() => history.push('/chat')}>
      {t('Check my previous queries')}
    </button>
  )
}

export default SearchButton
