export const filterValueTranslations = {
  industry: {
    'Business Services': '業種',
    'Consumer Goods': '消費財',
    'Consumer Services': '消費者サービス',
    Education: '教育',
    Entertainment: 'エンターテイメント',
    'Financial Services': '金融サービス',
    'Government and Nonprofit': '政府・非営利団体',
    Healthcare: 'ヘルスケア',
    Industrial: '産業',
    Media: 'メディア',
    Technology: 'テクノロジー',
  },
  theme: {
    AI: 'AI',
    Blockchain: 'ブロックチェーン',
    Climate: '気候',
    'AR/VR/XR': 'AR/VR/XR',
    'Digital Transformation': 'デジタルトランスフォーメーション',
    'Fintech and Financial Services': 'フィンテック・金融サービス',
    'Healthcare and Biotechnology': 'ヘルスケア・バイオテクノロジー',
    IoT: 'IoT',
    Cybersecurity: 'サイバーセキュリティ',
    'E-commerce and Retail Innovations': 'Eコマース・小売革新',
    'Remote Work and Collaboration Tools': 'リモートワーク・コラボレーションツール',
    'Data Analytics and Big Data': 'データ分析・ビッグデータ',
    'Robots and Automation': 'ロボット・自動化',
    'Smart Cities and Urban Development': 'スマートシティ・都市開発',
    'Supply Chain and Logistics': 'サプライチェーン・物流',
    'Education Technology': '教育テクノロジー',
    'Energy and Renewable Resources': 'エネルギー・再生可能資源',
    'Mobility and Transportation': 'モビリティ・輸送',
    'Media and Entertainment': 'メディア・エンターテイメント',
    'Legal and Regulatory Developments': '法規制の発展',
  },
  article_type: {
    Event: 'イベント',
    'New Product Launch': '新製品発表',
    'New Company': '新会社',
    Fundraise: '資金調達',
  },
  geography: {
    Hokkaido: '北海道',
    Tohoku: '東北',
    Kanto: '関東',
    Chubu: '中部',
    Kansai: '関西',
    Chugoku: '中国',
    Shikoku: '四国',
    Kyushu: '九州',
  },
}
