import React, { FC, forwardRef, ReactNode, useEffect, useState } from 'react'
import { IItemRendererProps, IMultiSelectProps, MultiSelect as MultiSelect_ } from '@blueprintjs/select'
import { Button, IActionProps, ITagInputProps, Menu, MenuItem } from '@blueprintjs/core'
import { queryIndex } from '../../../logic/Helpers'
import { highlightText, stateName } from '../../../logic/ValueFormatters'
import { COUNTRIES, Country } from '../../../resources/reference_data/countries'
import { FUNDING_TYPES } from '../../../resources/reference_data/funding_types'
import { industryGroups, IndustryGroup, NestedIndustry } from '../../../ui/components/MockIndustry'
import { ML_RATINGS, RATINGS_WITH_UNRATED } from '../../../resources/reference_data/ratings'
import { propertiesFromRating } from '../../UserRatingPicker'
import { STATES, STATES_MAP } from '../../../resources/reference_data/states'
import { CITIES } from '../../../resources/reference_data/cities'
import { OPERATING_STATUS_MAP, OPERATING_STATUSES } from '../../../resources/reference_data/operating_statuses'
import { FixedSizeList, FixedSizeListProps } from 'react-window'
import { COMPANY_TYPES, COMPANY_TYPES_ORDER } from '../../../resources/reference_data/company_types'
import { RatingLabel } from '../../RatingLabel'
import * as Backend from '../../../logic/Backend'
import { ListData } from '../../../logic/Backend'
import { showErrorToast } from '../../../logic/Toaster'
import { NoResults } from '../../NoResults'
import { salesTargetObject } from '../../../resources/reference_data/sales_target_types'
import { revenueStreamObject } from '../../../resources/reference_data/revenue_stream_types'
import { regions, subRegions } from '../../../resources/reference_data/geography_regions'
import NestedIndustries from './NestedIndustries'
import { useSelector, useDispatch } from 'react-redux'
import { useDeselectIndustryGroup } from './NestedIndustries/utils/NestedIndustryLogic'
import css from './MultiSelect.module.scss'
import { PIPELINE_STATUSES, pipelineStatusesForMultiSelect } from '../../../ui/pages/Pipeline/PipelineStatusPicker/pipelineStatusPicker'
import { t } from 'i18next'
import { filterIndustryValueTranslations } from '../../../public/locales/jp/translations'
import i18next from 'i18next'

type ItemKey = string
type CustomItemRenderer = (
  item: ItemKey,
  itemProps: IItemRendererProps,
  selectedItems: string[],
  onNestedItemSelect: (items: any, hasIndustry?: boolean, hasSubIndustry?: boolean) => void,
  industryState: IndustryGroup[],
  selectedIndustryObjects?: SelectedIndustryGroups[]
) => JSX.Element | null
type CustomTagRenderer = (item: ItemKey, query: string) => ReactNode
type ComparisonFunction = (query: string, item: ItemKey) => boolean
type HighlightFunction = (query: string, item: ItemKey) => ReactNode

type SelectedNestedIndustryItems = {
  industryGroup?: string
  industry?: string
  subIndustry?: string
}

export type SelectedIndustryGroups = {
  id?: number
  name: string
  industries?: NestedIndustry[]
  subIndustries?: SelectedIndustryGroups[]
}
export interface MenuSelectProps {
  allItems: ItemKey[]
  placeholder: string
  comparisonFn?: ComparisonFunction
  textWithHighlight?: HighlightFunction
  className?: string
  onChange?: (state: MultiSelectState) => void
  itemRenderer?: CustomItemRenderer
  tagRenderer?: CustomTagRenderer
  virtualizedProps?: { maxHeight: FixedSizeListProps['height']; width: FixedSizeListProps['width']; itemSize: FixedSizeListProps['itemSize'] }
  industryState?: IndustryGroup[]
}

export interface MultiSelectState {
  selectedItems?: ItemKey[]
  selectedNestedIndustryNames?: SelectedNestedIndustryItems[]
  IPIndustryState?: any
  query?: string
}

export const MultiSelect: FC<MenuSelectProps & MultiSelectState> = ({
  // this is state (...s)
  allItems,
  placeholder,
  comparisonFn,
  textWithHighlight,
  onChange,
  itemRenderer,
  tagRenderer,
  virtualizedProps,
  selectedItems,
  selectedNestedIndustryNames,
  IPIndustryState,
  query = '',
  className = '',
}) => {
  selectedItems = selectedItems ?? []
  const PADDING = 0
  const [selectedItemsProp, setSelectedItemsProp] = useState(selectedItems)
  const [selectedItems$, setSelectedItems$] = useState<string[]>(selectedItems)
  const [queryProp, setQueryProp] = useState(query)
  const [query$, setQuery$] = useState(query)
  const selectedIndustries = useSelector((state: any) => state.selectedNestedIndustryState)
  const [selectedIndustryObjects, setSelectedIndustryObjects] = useState<SelectedIndustryGroups[]>(selectedIndustries.allItems)
  const industryState: any = useSelector((state: any) => state.industryState)
  const { ...nestedFilterClickLogic } = useDeselectIndustryGroup()
  const dispatch = useDispatch()
  useEffect(() => {
    if (selectedItems && selectedItems.length > 0) {
      onChange?.({ selectedItems, ...(query && { query }) })
    } else if (selectedNestedIndustryNames || selectedIndustries.objectForBackend) {
      onChange?.({ selectedNestedIndustryNames: selectedNestedIndustryNames || selectedIndustries.objectForBackend, ...(query && { query }) })
    }
    IPIndustryState &&
      IPIndustryState.length > 0 &&
      dispatch({
        type: 'selectedNestedIndustryState/updateStateWithSavedFilters',
        payload: { objectForBackend: IPIndustryState, nestedIndustries: industryState.parsedIndustryData },
      })
    selectedNestedIndustryNames &&
      selectedNestedIndustryNames.length > 0 &&
      dispatch({
        type: 'selectedNestedIndustryState/updateStateWithSavedFilters',
        payload: { objectForBackend: selectedNestedIndustryNames, nestedIndustries: industryState.parsedIndustryData },
      })
  }, [])
  if (!scalarArraysAreEqual(selectedItemsProp, selectedItems)) {
    setSelectedItemsProp(selectedItems)
    setSelectedItems$(selectedItems)
  }

  if (queryProp !== query) {
    setQueryProp(query)
    setQuery$(query)
  }

  const textWithHighlight_: HighlightFunction = (query, item) => {
    return highlightText(item, query)
  }

  const itemPredicate: IMultiSelectProps<ItemKey>['itemPredicate'] = (query, item) => {
    if (item === undefined || query === undefined) {
      return false
    }
    return (comparisonFn ?? comparisonFn_)(query, item)
  }

  const comparisonFn_: ComparisonFunction = (query, item) => {
    return queryIndex(item, query) !== -1
  }

  const itemsEqual: IMultiSelectProps<ItemKey>['itemsEqual'] = (i1, i2) => {
    return i1 === i2
  }

  const itemListRendererVirtualized: IMultiSelectProps<ItemKey>['itemListRenderer'] = ({ filteredItems, renderItem }) => {
    return filteredItems.length === 0 ? (
      <Menu>
        <NoResults />
      </Menu>
    ) : (
      <FixedSizeList
        innerElementType={forwardRef((props, ref) => (
          <Menu ref={ref} {...props} style={{ ...props.style, height: props.style.height + PADDING * 2 }} />
        ))}
        height={Math.min(filteredItems.length * virtualizedProps!.itemSize, virtualizedProps!.maxHeight as number) + PADDING * 2}
        width={virtualizedProps!.width}
        itemSize={virtualizedProps!.itemSize}
        itemCount={filteredItems.length}
      >
        {({ index, style }) => {
          const person = filteredItems[index]
          const renderedItem = renderItem(person, index)
          return (
            <MenuItem
              {...renderedItem?.props}
              style={{
                ...style,
                width: `calc(100% - ${PADDING * 2}px)`,
                left: `${PADDING}px`,
                top: `${parseFloat(style.top as string) + PADDING}px`,
              }}
            />
          )
        }}
      </FixedSizeList>
    )
  }

  const itemRenderer_: CustomItemRenderer = (item, { handleClick, modifiers, index }, selectedItems) => {
    if (!modifiers.matchesPredicate) {
      return null
    }
    return (
      <MenuItem
        active={modifiers.active}
        icon={selectedItems.includes(item) ? 'tick' : 'blank'}
        disabled={modifiers.disabled}
        key={index}
        onClick={handleClick}
        text={(textWithHighlight ?? textWithHighlight_)(query$, item)}
        shouldDismissPopover={false}
      />
    )
  }

  const tagRenderer_: CustomTagRenderer = (selectedItem, query) => {
    return (textWithHighlight ?? textWithHighlight_)(query, selectedItem)
  }

  const onItemSelect: IMultiSelectProps<ItemKey>['onItemSelect'] = (item) => {
    const newItems = selectedItems$.includes(item) ? selectedItems$.filter((i) => i !== item) : [...selectedItems$, item]
    setSelectedItems$(newItems)
    setQuery$('')
    onChange?.({ selectedItems: newItems, ...(query && { query }) })
  }

  let newItems = selectedIndustries.allItems
  let itemsToDisplay: SelectedIndustryGroups[] = selectedIndustries.itemsToDisplay
  const onNestedItemSelect = (items: SelectedIndustryGroups[]) => {
    const selectedIGexists = newItems.find((obj: SelectedIndustryGroups) => obj?.id && obj.id == items[0].id)

    // Handle Industry Group Menu Click
    if (!items[1] && !items[2]) {
      const { ...res } = nestedFilterClickLogic.handleIndustryGroupClick(selectedIndustryObjects, newItems, items, itemsToDisplay)
      newItems = res.newItems
      itemsToDisplay = res.itemsToDisplay
    }
    // Handle Industry Click
    if (items[1] && !items[2]) {
      const { ...res } = nestedFilterClickLogic.handleIndustryClick(selectedIndustryObjects, newItems, items, itemsToDisplay, selectedIGexists)
      newItems = res.newItems
      itemsToDisplay = res.itemsToDisplay
    }
    // Handle SubIndustry Click
    if (items[2]) {
      const { ...res } = nestedFilterClickLogic.handleSubIndustryClick(selectedIndustryObjects, newItems, items, itemsToDisplay)
      newItems = res.newItems
      itemsToDisplay = res.itemsToDisplay
    }
    setSelectedIndustryObjects(newItems)
    setQuery$('')

    nestedFilterClickLogic.prepObjectForBackend()
    onChange?.({ selectedNestedIndustryNames: selectedIndustries.objectForBackend, ...(query && { query }) })
  }

  const onQueryChange: IMultiSelectProps<ItemKey>['onQueryChange'] = (query) => {
    setQuery$(query)
    onChange?.({ selectedItems: selectedItems$, ...(query && { query }) })
  }

  const onRemove: ITagInputProps['onRemove'] = (_, index) => {
    onItemSelect(selectedItems$[index])
  }

  const handleClear: IActionProps['onClick'] = () => {
    const emptyItems: ItemKey[] = []
    setSelectedItems$(emptyItems)
    onChange?.({ selectedItems: emptyItems, ...(query$ && { query: query$ }) })
    const { ...res } = nestedFilterClickLogic.clearEntireSelection(newItems, itemsToDisplay)
    newItems = res.newItems
    itemsToDisplay = res.itemsToDisplay
    setSelectedIndustryObjects(newItems)
  }
  const selectedIndustryNames: string[] = selectedIndustries.nestedIndustryNames
  const clearButton: ITagInputProps['rightElement'] = selectedItems$.length > 0 ? <Button icon="cross" minimal={true} onClick={handleClear} /> : <></>

  return (
    <MultiSelect_
      className={className}
      items={allItems}
      selectedItems={placeholder === 'Agriculture, Hotels, Airlines…' ? selectedIndustryNames : selectedItems$}
      {...(virtualizedProps && { itemListRenderer: itemListRendererVirtualized })}
      itemRenderer={(item, itemProps) =>
        (itemRenderer ?? itemRenderer_)(item, itemProps, selectedItems$, onNestedItemSelect, industryState, selectedIndustryObjects)
      }
      tagRenderer={(item) => (tagRenderer ?? tagRenderer_)(item, query$)}
      itemPredicate={itemPredicate}
      onQueryChange={onQueryChange}
      itemsEqual={itemsEqual}
      placeholder={placeholder}
      noResults={<NoResults />}
      onItemSelect={onItemSelect}
      resetOnQuery={false}
      query={query$}
      popoverProps={{ minimal: true, boundary: 'viewport', ...(virtualizedProps && { popoverClassName: 'virtualized-list' }) }}
      tagInputProps={{
        onRemove: onRemove,
        rightElement: clearButton,
      }}
      fill={true}
    />
  )
}

const countryMap = new Map<ItemKey, Country>(COUNTRIES.map((c) => [c.code, c]))
export const countriesPickerProps: MenuSelectProps = {
  placeholder: 'USA, China, Japan…',
  allItems: Array.from(countryMap.keys()),
  virtualizedProps: { maxHeight: 300, width: 300, itemSize: 30 },
  comparisonFn: (query, itemKey) => {
    const country = countryMap.get(itemKey)!
    return queryIndex(country.code, query) !== -1 || queryIndex(country.name, query) !== -1
  },
  textWithHighlight: (query, itemKey) => {
    const country = countryMap.get(itemKey)!
    return highlightText(country.emojiFlag + ' ' + country.name, query)
  },
}

export const statesPickerProps: MenuSelectProps = {
  placeholder: 'California, Utah…',
  allItems: STATES,
  virtualizedProps: { maxHeight: 300, width: 300, itemSize: 30 },
  comparisonFn: (query, stateCode) => {
    const stateName = STATES_MAP[stateCode]!
    return queryIndex(stateCode, query) !== -1 || queryIndex(stateName, query) !== -1
  },
  textWithHighlight: (query, stateCode) => {
    return highlightText(stateName(stateCode), query)
  },
}

export async function inListsPickerProps(): Promise<MenuSelectProps> {
  let lists: ListData[] = []
  try {
    lists = await Backend.lists()
  } catch (error) {
    showErrorToast('Failed to load lists')
  }
  return {
    placeholder: 'List A, List B…',
    allItems: lists.map((l) => l.id),
    itemRenderer: (item, { handleClick, modifiers, index, query }, selectedItems) => {
      if (!modifiers.matchesPredicate) {
        return null
      }
      const list = lists.find((l) => l.id === item)!
      return (
        <MenuItem
          active={modifiers.active}
          icon={selectedItems.includes(item) ? 'tick' : 'blank'}
          disabled={modifiers.disabled}
          key={index}
          onClick={handleClick}
          text={highlightText(list.name!, query)}
          shouldDismissPopover={false}
        />
      )
    },
    tagRenderer: (item, query) => {
      const list = lists.find((l) => l.id === item)!
      return highlightText(list.name!, query)
    },
    comparisonFn: (query, item) => {
      const list = lists.find((l) => l.id === item)!
      return queryIndex(list.name!, query) !== -1
    },
  } as MenuSelectProps
}

export const citiesPickerProps: MenuSelectProps = {
  placeholder: 'New York, Miami…',
  allItems: CITIES,
  virtualizedProps: { maxHeight: 300, width: 300, itemSize: 30 },
}

const fundingStagesMap = new Map<ItemKey, string>(Object.entries(FUNDING_TYPES))
export const fundingStagesPickerProps: MenuSelectProps = {
  placeholder: 'Seed, Angel, Series A…',
  allItems: Array.from(fundingStagesMap.keys()),
  comparisonFn: (query, itemKey) => queryIndex(fundingStagesMap.get(itemKey)!, query) !== -1,
  textWithHighlight: (query, itemKey) => highlightText(fundingStagesMap.get(itemKey)!, query),
  virtualizedProps: { maxHeight: 300, width: 300, itemSize: 30 },
}

const getTranslatedIndustry = (value: string) => {
  if (i18next.language === 'jp') {
    return filterIndustryValueTranslations.industry?.[value] || value
  }
  return value
}

export const useIndustryPickerProps = () => {
  const parsedIndustryState: IndustryGroup[] = useSelector((state: any) => state.industryState.parsedIndustryData)
  const industryGroups = parsedIndustryState.map((ind) => ind.name)
  const returnedProps: MenuSelectProps = {
    placeholder: t('Agriculture, Hotels, Airlines…'),
    allItems: industryGroups,
    tagRenderer: (item) => {
      return (
        <div>
          <span className={css.itemText}>{getTranslatedIndustry(item)}</span>
          <span className={css.buttonBlocker}></span>
        </div>
      )
    },
    itemRenderer: (item, { handleClick, modifiers, index }, selectedItems, onNestedItemSelect, nestedIndustries, selectedIndustryObjects) => {
      return (
        <NestedIndustries
          modifiers={modifiers}
          selectedItems={selectedItems}
          index={index}
          item={item}
          handleClick={handleClick}
          onNestedItemSelect={onNestedItemSelect}
          industryState={parsedIndustryState}
          selectedIndustryObjects={selectedIndustryObjects}
          getTranslation={getTranslatedIndustry}
        />
      )
    },
  }
  return returnedProps
}

export const industriesPickerProps: MenuSelectProps = {
  placeholder: t('Agriculture, Hotels, Airlines…'),
  allItems: industryGroups,
  itemRenderer: (item, { handleClick, modifiers, index }, selectedItems, onNestedItemSelect, industryState) => {
    return (
      <NestedIndustries
        modifiers={modifiers}
        selectedItems={selectedItems}
        index={index}
        item={item}
        handleClick={handleClick}
        onNestedItemSelect={onNestedItemSelect}
        industryState={industryState}
      />
    )
  },
}

export const operatingStatusesPickerProps: MenuSelectProps = {
  placeholder: 'IPO, Closed…',
  allItems: OPERATING_STATUSES,
  textWithHighlight: (query, item) => highlightText(OPERATING_STATUS_MAP[item], query),
}

export const ratingsPickerProps: (mlRating: boolean) => MenuSelectProps = (mlRating) => {
  return {
    placeholder: t('Love, Like, Maybe, Pass'),
    allItems: mlRating ? Array.from(ML_RATINGS, (_, i) => i.toString()) : Array.from(RATINGS_WITH_UNRATED, (_, i) => i.toString()),
    itemRenderer: (item, { handleClick, modifiers, index, query }, selectedItems) => {
      if (!modifiers.matchesPredicate) {
        return null
      }
      const rp = propertiesFromRating(item, mlRating)!
      return (
        <MenuItem
          active={modifiers.active}
          icon={selectedItems.includes(item) ? 'tick' : 'blank'}
          disabled={modifiers.disabled}
          key={index}
          onClick={handleClick}
          text={<RatingLabel rp={rp} text={highlightText(rp.label, query)} />}
          shouldDismissPopover={false}
        />
      )
    },
    tagRenderer: (item, query) => {
      const rp = propertiesFromRating(item, mlRating)!
      return <RatingLabel rp={rp} text={highlightText(rp?.label, query)} noPadding={true} />
    },
  }
}

// MultiSelect.tsx
export const pipelineStatusProps = (): MenuSelectProps => {
  // const allStatuses = Object.keys(PIPELINE_STATUSES)

  //Temporarily removing no status from ui until it is fixed
  const allStatuses = Object.keys(PIPELINE_STATUSES).filter((status) => PIPELINE_STATUSES[status] !== 'No Status')

  return {
    placeholder: 'Select Status',
    allItems: allStatuses,
    itemRenderer: (item, { handleClick, modifiers, index, query }, selectedItems) => {
      const statusText = PIPELINE_STATUSES[item]
      const isItemSelected = selectedItems.includes(item)
      return (
        <MenuItem
          active={modifiers.active}
          icon={isItemSelected ? 'tick' : 'blank'}
          disabled={modifiers.disabled}
          key={index}
          onClick={handleClick}
          text={<span>{highlightText(statusText, query)}</span>}
          shouldDismissPopover={false}
        />
      )
    },
    tagRenderer: (item, query) => {
      const statusText = PIPELINE_STATUSES[item]
      return <span>{highlightText(statusText, query)}</span>
    },
  }
}

export const companyTypesPickerProps: MenuSelectProps = {
  placeholder: 'Privately Held, Public Company, Partnership...',
  allItems: COMPANY_TYPES_ORDER.map((x) => x.toString()),
  comparisonFn: (query, item) => queryIndex(COMPANY_TYPES[parseInt(item)], query) !== -1,
  textWithHighlight: (query, item) => highlightText(COMPANY_TYPES[parseInt(item)], query),
}

export function scalarArraysAreEqual<T>(a1: T[], a2: T[]): boolean {
  return a1.length === a2.length && a1.every((value, index) => value === a2[index])
}

export const salesTargetPickerProps: MenuSelectProps = {
  placeholder: 'B2B, B2C, B2B + B2C',
  allItems: salesTargetObject.queryValues.map((x) => x.toString()),
  // textWithHighlight fn used to change displayed filters (e.g. '0' -> B2B), takes display val
  textWithHighlight: (query, item) => highlightText(salesTargetObject.displayNames[parseInt(item)], query),
}

export const revenueStreamProps: MenuSelectProps = {
  placeholder: 'Recurring, Non-recurring',
  allItems: revenueStreamObject.queryValues.map((x) => x.toString()),
  // item returns number val as string, e.g. what is passed to query
  textWithHighlight: (query, item) => highlightText(revenueStreamObject.displayNames[parseInt(item)], query),
}
export const regionProps: MenuSelectProps = {
  placeholder: 'Americas, Europe...',
  allItems: regions.map((x) => x.toString()),
}
export const subRegionProps: MenuSelectProps = {
  placeholder: 'Select a geography sub region',
  allItems: subRegions.map((x) => x.toString()),
}
