import React, { FC, ReactNode } from 'react'
import styles from './styles.module.scss'

interface ContainerComponentProps {
  title?: string
  seeMoreButton?: ReactNode
  renderedItems: ReactNode
}

const ContainerComponent: FC<ContainerComponentProps> = ({ title, seeMoreButton, renderedItems }) => {
  return (
    <div className={styles.companies}>
      <div className={styles.companies__header}>{/* <h2>{title}</h2> */}</div>
      <div className={styles.companies__list}>{renderedItems}</div>
    </div>
  )
}

export default ContainerComponent
