import React, { FC, useState } from 'react'
import { Menu, MenuItem, Popover } from '@blueprintjs/core'
import { labelFromVariable } from '../../logic/ValueFormatters'
import { OmniFilterButton } from '../OmniFilterButton'
import { t } from 'i18next'

export const searchLogicMap = {
  numberRange: ['between'] as const,
  set: ['containsAny', 'containsAll', 'doesNotContain'] as const,
  contains: ['contains', 'doesNotContain'] as const,
  text: ['containsAny', 'containsAll', 'doesNotContain'] as const,
  socialMedia: ['hasAccount', 'doesNotHaveAccount'] as const,
}

export type SearchLogicKey = keyof typeof searchLogicMap
export type SearchLogicValue = (typeof searchLogicMap)[SearchLogicKey][number]

export interface SearchLogicDropdownProps {
  type: SearchLogicKey
  value?: SearchLogicValue
  onChange?: (value: SearchLogicValue) => void
  fixed?: boolean
}

export const SearchLogicDropdown: FC<SearchLogicDropdownProps> = ({ type, value, onChange, fixed }) => {
  const [typeProp, setTypeProp] = useState(type)
  const [valueProp, setValueProp] = useState(value)
  const [value$, setValue$] = useState(value)
  const [isOpen, setIsOpen] = useState(!value)

  if (type !== typeProp) {
    setTypeProp(type)
  }

  if (value !== valueProp) {
    setValueProp(value)
    setValue$(value)
    setIsOpen(!value)
  }

  function onChange_(option: SearchLogicValue): void {
    setIsOpen(false)
    setValue$(option)
    onChange?.(option)
  }

  const options = searchLogicMap[type] as unknown as SearchLogicValue[]

  const button = <OmniFilterButton tagText={t('Search Logic')} text={t(labelFromVariable(value$))} fixed={fixed} />
  if (fixed || options.length === 0) {
    return button
  }

  return (
    <>
      <Popover
        popoverClassName={'w-190'}
        position={'bottom'}
        minimal={true}
        fill={true}
        boundary={'viewport'}
        isOpen={isOpen}
        onInteraction={(open) => value$ && setIsOpen(open)}
      >
        {button}
        <Menu>
          {options.map((option, i) => (
            <MenuItem text={t(labelFromVariable(option))} key={i} onClick={() => onChange_(option)} shouldDismissPopover={false} />
          ))}
        </Menu>
      </Popover>
    </>
  )
}
