import { CompanyData, PipelineData } from '../Backend'
export type FilterId = keyof CompanyData | 'combined_keyword' | 'search_bar'

export class Column {
  constructor(public id: FilterId, public label: string) {}

  static companyColumns: Column[] = [
    new Column('ml_rating', 'Kurrant.ai Rating'),
    new Column('rating', 'Your Rating'),
    new Column('pipeline_cards', 'Pipeline Status'),
    new Column('list_ids', 'In Lists'),
    new Column('recommended_score', 'Recommended Score'),
    new Column('created_at', 'Created At'),

    new Column('combined_keyword', 'Combined Keyword Search'),
    new Column('name', 'Name'),
    new Column('name_jp', 'Name'),
    new Column('domain', 'Domain'),
    new Column('short_description', 'Short Description'),
    new Column('short_description_jp', 'Short Description'),
    new Column('description', 'Description'),
    new Column('description_jp', 'Description'),
    new Column('founded_on_date', 'Founded In'),
    new Column('country_iso_3166_1_alpha_3', 'Country'),
    new Column('state', 'State'),
    new Column('city', 'City'),
    new Column('postal_code', 'Postal Code'),
    new Column('industry_groups', 'Industry Group'),
    new Column('industries', 'Industry'),
    new Column('sub_industries', 'Sub Industries'),
    new Column('status_enum', 'Operating Status'),
    new Column('li_type_enum', 'Company Type'),
    new Column('employee_count_int4range', 'Employee Range'),

    new Column('li_employee_count_integer', 'LinkedIn Employees'),
    new Column('li_employee_count_mom_decimal', 'LinkedIn Employees Monthly Growth'),
    new Column('bw_revenue_usd_bigint', 'Estimated Sales Revenue'),
    new Column('bw_revenue_mom_decimal', 'Estimated Sales Revenue Monthly Growth'),
    new Column('tech_spent_bigint_usd', 'Tech Spend'),
    new Column('ig_followers_count_integer', 'Instagram Followers'),
    new Column('ig_followers_count_mom_decimal', 'Instagram Followers Monthly Growth'),
    new Column('li_followers_count_integer', 'LinkedIn Followers'),
    new Column('li_followers_count_mom_decimal', 'LinkedIn Followers Monthly Growth'),

    new Column('total_assets_bigint_usd', 'Total Assets'),
    new Column('revenue_bigint_usd', 'Revenue'),
    new Column('net_income_bigint_usd', 'Net Income'),
    new Column('operating_income_bigint_usd', 'Operating Income'),

    new Column('total_funding_usd_bigint', 'Total Funding'),
    new Column('investors', 'Investors'),
    new Column('latest_funding_amount_bigint', 'Latest Funding'),
    new Column('latest_funding_on_date', 'Latest Funding In'),
    new Column('latest_funding_type', 'Latest Funding Type'),
    new Column('funding_round_count_integer', 'Funding Rounds'),
    new Column('exit_count_integer', 'Exit Count'),

    new Column('linkedin_url', 'LinkedIn'),
    new Column('facebook_url', 'Facebook'),
    new Column('twitter_url', 'Twitter'),
    new Column('ig_username', 'Instagram'),
    new Column('pinterest_url', 'Pinterest'),

    new Column('address', 'Address'),
    new Column('emails', 'Email'),
    new Column('phones', 'Phone'),

    new Column('sales_target_type', 'Sales Target'),
    new Column('revenue_stream_type', 'Revenue Stream'),
    new Column('industry', 'Industry'),
    new Column('region', 'Region'),
    new Column('sub_region', 'Sub Region'),
  ]

  static withId(id: FilterId): Column {
    return this.companyColumns.find((c) => c.id === id)!
  }
}
