import React, { useState } from 'react'
import styles from './style.module.scss'
import { CbLiOrPlaceholderImage } from '../../Company/CbLiOrPlaceholderImage'
import FallbackImage from '../../../../resources/images/logo.png'
import { pluralize } from '../../../../logic/ValueFormatters'
import { t } from 'i18next'
import CaretDown from '../../../../resources/images/caret-down.svg'
interface Props {
  companyName: string
  articleDate: string
  articleTitle: string
  articleSummary: string
  tags?: string[]
  companyId: string
  logo?: string
  url?: string
  srcImg?: string
}

const NewsItem = ({ companyName, articleDate, articleTitle, articleSummary, companyId, logo, url, srcImg }: Props) => {
  const [imageError, setImageError] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const handleExpand = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setExpanded(!expanded)
  }
  return (
    <div className={styles.newsItemContainer} onClick={() => window.open(url, '_blank')}>
      <div className={styles.imageContainer}>
        {imageError ? (
          <div className={styles.fallbackImageContainer}>
            <img src={FallbackImage} className={styles.newsImageSmall} />
          </div>
        ) : (
          <img className={styles.newsImage} src={srcImg} onError={() => setImageError(true)} />
        )}
      </div>
      <div className={styles.newsTextContainer}>
        <p className={styles.newsPublishedDate}>{formatDate(articleDate)}</p>
        <div className={styles.newsItemHeading}>
          <p className={styles.articleTitle}>{articleTitle}</p>
          <div className={styles.summaryContainer}>
            <p className={expanded ? styles.newsSummaryExpanded : styles.newsSummaryCollapsed}>{articleSummary}</p>
            <span onClick={handleExpand}>
              <img className={expanded ? styles.newsCaretIconUp : styles.newsCaretIconDown} src={CaretDown} />
            </span>
          </div>
          <a onClick={(e) => e.stopPropagation()} href={`/companies/${companyId}`} className={styles.clickableLink}>
            <CbLiOrPlaceholderImage style={{ border: '1px solid rgba(72, 72, 73, 0.20)', borderRadius: 35 }} logo_id={logo} altText={'Logo'} size={30} />
            <span className={styles.companyName}>{companyName}</span>
          </a>
          {/* {tags?.map((tag) => (
            <span key={tag} className={styles.tag}>
              {tag}
            </span>
          ))}{' '} */}
        </div>
      </div>
    </div>
  )
}

export default NewsItem

const formatDate = (dateString: string): string => {
  const articleDateObj = new Date(dateString)
  const now = new Date()

  // Calculate the difference in milliseconds
  const diffInMs = now.getTime() - articleDateObj.getTime()
  const diffInHours = diffInMs / (1000 * 60 * 60)
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24))

  // Return the formatted time difference
  if (diffInHours < 24) {
    if (diffInHours < 1) {
      return t('Less than an hour ago')
    }
    return `${Math.floor(diffInHours)} ${pluralize(t('hour'), '', 's', Math.floor(diffInHours))} ${t('ago')}`
  } else {
    return `${diffInDays} ${pluralize(t('day'), '', 's', diffInDays)} ${t('ago')}`
  }
}
