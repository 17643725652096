export const industryTranslations = {
  'Business Services': {
    en: 'Business Services',
    jp: 'ビジネスサービス',
  },
  'Consumer Goods': {
    en: 'Consumer Goods',
    jp: '消費財',
  },
  'Consumer Services': {
    en: 'Consumer Services',
    jp: '消費者サービス',
  },
  Education: {
    en: 'Education',
    jp: '教育',
  },
  Entertainment: {
    en: 'Entertainment',
    jp: 'エンターテイメント',
  },
  'Financial Services': {
    en: 'Financial Services',
    jp: '金融サービス',
  },
  'Government and Nonprofit': {
    en: 'Government and Nonprofit',
    jp: '政府・非営利団体',
  },
  Healthcare: {
    en: 'Healthcare',
    jp: 'ヘルスケア',
  },
  Industrial: {
    en: 'Industrial',
    jp: '産業',
  },
  Media: {
    en: 'Media',
    jp: 'メディア',
  },
  Technology: {
    en: 'Technology',
    jp: 'テクノロジー',
  },
}

export const getTranslatedIndustry = (industry: string | undefined, language: string): string => {
  if (!industry) return ''
  return industryTranslations[industry]?.[language === 'jp' ? 'jp' : 'en'] || industry
}
