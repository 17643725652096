import React, { FC, useRef, useEffect, ReactElement, useState } from 'react'
import ContainerComponent from '../components/ContainerComponent'
import styles from '../components/styles.module.scss'
import { useFetchCompanies } from '../components/useFetchCompanies'
import CompanyItem from '../components/CompanyItem'
import SeeMoreButton from '../components/SeeMoreButton'
import { getRecommendedCompanies } from '../../../../logic/Backend'
import Skeleton from 'react-loading-skeleton'
import { useHistory } from 'react-router-dom'
import { t } from 'i18next'

export const RecommendedCompanies: FC = () => {
  const { companies, visibleCompanies, setFrom, total_count, loading } = useFetchCompanies(0, 10, getRecommendedCompanies)
  const [initialLoading, setInitialLoading] = useState(true)
  const [paginationLoading, setPaginationLoading] = useState(false)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const previousScrollHeightRef = useRef<number>(0)
  const history = useHistory()

  const uniqueVisibleCompanies = Array.from(new Set(visibleCompanies.map((company) => company.id))).map((id) =>
    visibleCompanies.find((company) => company.id === id)
  )

  const sortedVisibleCompanies = uniqueVisibleCompanies
    .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
    .sort((a, b) => b.recommended_score - a.recommended_score)

  const handleScroll = (e: React.UIEvent<HTMLDivElement>): void => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
    if (scrollHeight - scrollTop <= clientHeight + 1 && companies.length < total_count && !paginationLoading && visibleCompanies.length < 25) {
      previousScrollHeightRef.current = scrollHeight
      setPaginationLoading(true)
      setFrom((prevFrom) => prevFrom + visibleCompanies.length)
    }
  }

  useEffect(() => {
    if (scrollContainerRef.current) {
      const scrollContainer = scrollContainerRef.current
      scrollContainer.scrollTop = previousScrollHeightRef.current
    }
  }, [visibleCompanies])

  useEffect(() => {
    if (!loading) {
      setInitialLoading(false)
      setPaginationLoading(false)
    }
  }, [loading])

  const handleSeeMoreClick = () => {
    history.push({
      pathname: '/companies',
      state: {
        sortModel: [{ colId: 'recommended_score', sort: 'desc' }],
      },
    })
  }

  const renderedItems = (
    <div className={styles.scrollable} onScroll={handleScroll} ref={scrollContainerRef}>
      {initialLoading ? (
        <Skeleton count={8} height={80} />
      ) : (
        sortedVisibleCompanies.map((company) => (
          <CompanyItem
            key={company.id}
            company={company}
            additionalInfo={
              <div className={styles.companies__score}>
                <div className={styles.circularScore} style={{ '--score': company.recommended_score } as React.CSSProperties}>
                  <span className={styles.scoreText}>{Math.floor(company.recommended_score)}</span>
                </div>
              </div>
            }
          />
        ))
      )}
      {paginationLoading && <Skeleton count={1} height={80} />}
      {visibleCompanies.length >= 25 && <SeeMoreButton onClick={handleSeeMoreClick} />}
    </div>
  )

  return (
    <div className={styles.fullWidthContainer}>
      <h2 className={styles.header}>{t('Recommended by Kurrant')}</h2>
      <ContainerComponent seeMoreButton={<SeeMoreButton onClick={handleSeeMoreClick} />} renderedItems={renderedItems} />
    </div>
  )
}

export default RecommendedCompanies
