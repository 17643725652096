import React from 'react'
import { ControlGroup } from '@blueprintjs/core'
import Filter from './Filter'
import styles from './style.module.scss'
import { t } from 'i18next'
import i18next from 'i18next'
import { filterValueTranslations } from './translations'

interface Props {
  filterValues: { industry: string[]; theme: string[]; article_type: string[]; geography: string[] }
  onFilterSelected: (key: string, value: string) => void
  handleClear: () => void
  shouldClearFilter: boolean
  resetClearFilter: () => void
}

const FilterContainer = ({ filterValues, onFilterSelected, handleClear, shouldClearFilter, resetClearFilter }: Props) => {
  const filterGroups = {
    industry: t('Industries'),
    theme: t('Themes'),
    article_type: t('Type'),
    geography: t('Geography'),
  }

  const getTranslatedLabel = (filter: string, value: string) => {
    // Only translate if language is Japanese
    if (i18next.language === 'jp') {
      return filterValueTranslations[filter]?.[value] || value
    }
    return value
  }

  return (
    <div className={styles.filterContainer}>
      <span>{t('Filter by:')}</span>
      <ControlGroup>
        {Object.keys(filterGroups).map((filter, i) => {
          const optionsFromValues = filterValues[filter]?.map((value) => ({
            value, // Original value for backend
            label: getTranslatedLabel(filter, value), // Translated value for display
          }))

          return (
            <Filter
              key={i}
              category={filter}
              label={filterGroups[filter]}
              values={optionsFromValues || []}
              onFilterSelected={onFilterSelected}
              shouldClearFilter={shouldClearFilter}
              resetClearFilter={resetClearFilter}
            />
          )
        })}
      </ControlGroup>
      <span className={`${styles.alignRight} ${styles.clickableLink}`} onClick={handleClear}>
        {t('Clear Filters')}
      </span>
    </div>
  )
}

export default FilterContainer
