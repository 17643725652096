import React from 'react'
import styles from './styles.module.scss'
import ActivityListItem from './ActivityListItem'
import { ActivityType } from '../../../../logic/Backend'
import { DateTime } from 'luxon'
import Skeleton from 'react-loading-skeleton'
import { t } from 'i18next'

interface Props {
  data?: ActivityType[]
  loading: boolean
}

const TeamActivity = (props: Props) => {
  const { data, loading } = props
  const localization = localStorage.getItem('language')

  const timePartitions = {
    today: [],
    thisWeek: [],
    thisMonth: [],
    older: [],
  }

  if (data?.length > 0) {
    for (const res of data) {
      if (partitionDates(res.created_at) === 'today') {
        timePartitions.today.push(res)
      } else if (partitionDates(res.created_at) === 'week') {
        timePartitions.thisWeek.push(res)
      } else if (partitionDates(res.created_at) === 'month') {
        timePartitions.thisMonth.push(res)
      } else {
        timePartitions.older.push(res)
      }
    }
  }

  return data && data.length <= 0 ? (
    <></>
  ) : (
    <div>
      <h2 className={styles.header}>{t('Team Activity')}</h2>
      {loading && <Skeleton count={1} height={80} width={500} />}
      <div className={styles.activityItemsContainer}>
        {Object.keys(timePartitions).map((timePartition) => {
          if (timePartitions[timePartition].length === 0) return null
          return (
            <div key={timePartition} className={styles.teamActivityHolder}>
              <div className={styles.timePartitionHeader}>{formatKeyText(timePartition)}</div>
              <div className={styles.partitionedActivityContainer}>
                {timePartitions[timePartition].map((activity, index) => (
                  <ActivityListItem
                    key={activity.id}
                    title={localization === 'jp' ? activity.title_jp : activity.title}
                    body={localization === 'jp' ? activity.body_jp : activity.body}
                    userName={activity.user_name}
                    action={activity.activity}
                    companyId={activity.company_id}
                  />
                ))}
              </div>
            </div>
          )
        })}
      </div>
      {/* {data?.map((activity) => (
        <ActivityListItem key={activity.id} title={activity.title} body={activity.body} userName={activity.user_name} />
      ))} */}
    </div>
  )
}

export default TeamActivity

function partitionDates(dateString: string): string {
  const date = DateTime.fromISO(dateString).startOf('day')
  const now = DateTime.now().startOf('day')
  const diff = now.diff(date, 'days').toObject()
  if (diff.days !== undefined) {
    if (diff.days < 1) return 'today'
    if (diff.days < 7) return `week`
    if (diff.days <= 30) return `month`
    return 'older'
  }
}

function formatKeyText(heading: string) {
  if (heading === 'today') return t('Today')
  if (heading === 'thisWeek') return t('Previous 7 Days')
  if (heading === 'thisMonth') return t('Previous 30 Days')
  if (heading === 'older') return t('Over a month ago')
}
