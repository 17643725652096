import React from 'react'
import styles from './styles.module.scss'
import LoveIcon from '../../../../resources/images/kurrant-love-icon.svg'
import AddToListIcon from '../../../../resources/images/kurrant-add-to-list-icon.svg'
import NewNoteIcon from '../../../../resources/images/kurrant-new-note-icon.svg'
import LaterIcon from '../../../../resources/images/kurrant-later-icon.svg'
import LikeIcon from '../../../../resources/images/kurrant-like-icon.svg'
import PassIcon from '../../../../resources/images/kurrant-pass-icon.svg'
import Unrate from '../../../../resources/images/kurrant-unrate-icon.svg'

interface Props {
  title: string
  body?: string
  userName: string
  action: string
  companyId?: string
  activityId?: string
}

const iconMap = {
  new_note: NewNoteIcon,
  new_list: AddToListIcon,
  rated_love: LoveIcon,
  rated_like: LikeIcon,
  rated_later: LaterIcon,
  rated_pass: PassIcon,
  rated_no_rating: Unrate,
}
const ActivityListItem = ({ title, body, companyId, userName, action }: Props) => {
  const IconComponent = <img src={iconMap[action] || null} />
  return (
    <div className={styles.flexContainer}>
      <span className={styles.teamActivityAvatar}> {IconComponent && <div className={styles.iconContainer}>{IconComponent}</div>}</span>
      <div className={styles.flexColContainer}>
        <div className={styles.boldInfo}>{title}</div>
        <div className={styles.plainText}>{companyId ? <a href={`/companies/${companyId}`}>{body}</a> : body}</div>
      </div>
    </div>
  )
}

export default ActivityListItem
