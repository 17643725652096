import React, { FC, ReactElement, useEffect, useState } from 'react'
import styles from './index.module.scss'
import * as Backend from '../../../logic/Backend'
import { CompanyData, companyPage, getNews, Indexed } from '../../../logic/Backend'
import * as Routes from '../../../logic/Routes'
import { hrefForId, useQueryParams } from '../../../logic/Routes'
import {
  companyLink,
  CountryFormat,
  flagAndCountryName,
  industries,
  internationalUnits,
  InterpretedHtml,
  linkedinUrlNormalization,
  linkFromUrlOrDomain,
  liType,
  mailtoLinks,
  rangeString,
  totalFundingRecap,
  withoutHighlight,
  year,
} from '../../../logic/ValueFormatters'
import { TopBar } from '../../components/TopBar'
import { Cache, Set } from '../Standard'
import { CompanyName } from './CompanyName'
import { BreadcrumbProps } from '@blueprintjs/core'
import { CbLiOrPlaceholderImage } from './CbLiOrPlaceholderImage'
import { OPERATING_STATUS_MAP } from '../../../resources/reference_data/operating_statuses'
import { FUNDING_TYPES } from '../../../resources/reference_data/funding_types'
import { Latest6MonthsChart } from '../../../stories/Latest6MonthsChart'
import { CompanySocialMediaDetailBlock } from '../../components/DetailBlockWidget/CompanySocialMediaDetailBlock'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { CompanyDetailsCard } from '../../../stories/CompanyDetailsCard'
import { CompanyDataPoint } from './CompanyDataPoint'
import { LinkedinEmployee } from '../../../stories/LinkedinEmployee'
import { KeyContact } from '../../../stories/KeyContact'
import RightSidebar from './RightSidebar'
import { UserRatingPicker } from '../../../stories/UserRatingPicker'
import InstagramCard from '../../../stories/InstagramCard'
import Masonry from 'react-masonry-css'
import Insights, { InsightsDataType } from './Insights'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import SimilarCompanies, { SimilarCompaniesType } from './SimilarCompanies'
import CompanyNotes from './CompanyNotes'
import CompanyNewsCard from './CompanyNews'
import CompanyStatusDropDown from '../Pipeline/components/CompanyStatusDropDown'
import Markdown from 'markdown-to-jsx'

const encodeS3URI = require('node-s3-url-encode')

export interface CompanyPageProps {
  id: Indexed['id']
  cache?: Cache
  setCache: Set<Cache | undefined>
}

const CompanyPage: FC<CompanyPageProps & WithNamespaces & IncludeT> = (props) => {
  const [company, setCompany] = useState(props.cache?.doubleClickedCompany)
  const [news, setNews] = useState()
  const [similarCompanies, setSimilarCompanies] = useState<(CompanyData & SimilarCompaniesType)[] | undefined>()
  const { focusedListId, focusedListName } = useQueryParams(['focusedListId', 'focusedListName'])
  const localization = localStorage.getItem('language')
  const companyName = withoutHighlight(localization === 'jp' && company?.name_jp ? company?.name_jp : company?.name)

  useEffect(() => {
    const getCompany = async () => {
      if (!company) {
        setCompany(await companyPage(props.id))
      }
    }
    const getCompanyNews = async () => {
      if (company) {
        setNews(await getNews(company.id))
      }
    }
    getCompany()
    getCompanyNews()
  }, [company])
  const hash = useLocation().hash as TabFragment
  const { t } = props
  const companyBreadcrumb = (): BreadcrumbProps => {
    return {
      href: hrefForId(Routes.company, props.id),
      text: companyName,
      current: true,
    }
  }
  const listBreadcrumb = (focusedListId: string): BreadcrumbProps => {
    return {
      href: hrefForId(Routes.list, focusedListId),
      text: focusedListName,
    }
  }

  const breadcrumbs: BreadcrumbProps[] = focusedListId
    ? [Routes.lists, listBreadcrumb(focusedListId), companyBreadcrumb()]
    : [Routes.companies, companyBreadcrumb()]

  useEffect(() => {
    ;(async () => {
      if (company === undefined) {
        setCompany(await Backend.companyPage(props.id))
      }
      if (similarCompanies === undefined) {
        setSimilarCompanies(await Backend.similarCompanies(props.id))
      }
    })()
  }, [])

  const companyFormattedAddress = () => {
    if (company?.address == null) {
      return 'n/a'
    }
    return [company?.address, company?.city, company?.state, company?.postal_code].filter((el) => el != null).join(', ')
  }

  const linkedinUrl = linkedinUrlNormalization(company?.linkedin_url)
  const link = companyLink(company?.homepage, company?.domain)
  const pipelineStatus = company?.pipeline_status
  const organizationId = company?.id || null

  function Header() {
    return (
      <div>
        <div style={{ margin: '40px 80px 20px 80px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                <CompanyName name={companyName} link={link} />
                {company && <UserRatingPicker company={company} rating={company.rating} large={true} />}
                <CompanyStatusDropDown organization_id={organizationId} pipeline_status={pipelineStatus} />
              </div>
              {company?.emails && (
                <div style={{ fontSize: '16px', marginTop: '5px' }}>
                  <InterpretedHtml html={mailtoLinks(company?.emails)} />
                </div>
              )}
              <CompanySocialMediaDetailBlock
                linkedin_url={linkedinUrl}
                twitter_url={company?.twitter_url}
                facebook_url={company?.facebook_url}
                instagram_url={company?.ig_username ? company?.instagram_url : undefined}
                pinterest_url={company?.pinterest_url}
                size={22}
                style={{ marginTop: company?.emails ? '16px' : '5px' }}
              />
            </div>
            <CbLiOrPlaceholderImage
              logo_id={company?.logo_id}
              li_logo_url={company?.li_logo_url}
              style={{ marginLeft: 'auto', maxHeight: '100px' }}
              altText={'Logo'}
            />
          </div>
          <div style={{ display: 'flex', gap: '24px', marginLeft: '-23px', maxHeight: 'fit-content', flexWrap: 'wrap' }}>
            <CompanyDataPoint
              label={t('Employee Count')}
              value={internationalUnits(company?.li_employee_count_integer, 1, '', 'n/a')}
              labelSize={'large'}
              alignment={'center'}
              style={{ width: '150px', flexShrink: '0' }}
            />
            <CompanyDataPoint
              label={t('Country')}
              value={flagAndCountryName(company?.country_iso_3166_1_alpha_3, CountryFormat.Code) ?? 'n/a'}
              labelSize={'large'}
              alignment={'center'}
              style={{ width: '150px', flexShrink: '0' }}
            />
            <CompanyDataPoint
              label={t('Operating Status')}
              value={company?.status_enum ? OPERATING_STATUS_MAP[company.status_enum] : 'n/a'}
              labelSize={'large'}
              alignment={'center'}
              style={{ width: '150px', flexShrink: '0' }}
            />
            <CompanyDataPoint
              label={t('Total Funding')}
              value={internationalUnits(company?.total_funding_usd_bigint, 1, '$', 'n/a')}
              labelSize={'large'}
              alignment={'center'}
              style={{ width: '150px', flexShrink: '0' }}
            />
            <CompanyDataPoint
              label={t('Investor')}
              value={company?.investors?.split(', ')?.[0] ?? 'n/a'}
              labelSize={'large'}
              alignment={'center'}
              style={{ minWidth: '150px', maxWidth: '509px' }}
            />
          </div>
        </div>
      </div>
    )
  }
  function Tab<T>(label: string, hash_: TabFragment, thingsToDisplay?: T[]): ReactElement {
    if (thingsToDisplay?.some((t) => t != null) ?? true) {
      return (
        <Link to={{ hash: hash_ }} {...(hash_ === hash && { className: styles.current })}>
          {label}
        </Link>
      )
    } else {
      return <span className={styles.disabled}>{label}</span>
    }
  }

  function Tabs() {
    return (
      <ul className={styles.tabs}>
        <li>
          {Tab(t('Kurrant.ai Insights'), '#insights', [
            insightsData.sub_region,
            insightsData?.region,
            insightsData?.industries,
            insightsData?.revenue_stream,
            insightsData?.sales_target,
          ])}
        </li>
        <li>{Tab(t('Basics'), '')}</li>
        <li>{Tab(t('Key Contacts'), '#keyContacts', [company?.people])}</li>
        <li>
          {Tab(t('Growth'), '#growth', [
            company?.tech_spent_bigint_usd,
            company?.ig_followers_count_integer,
            company?.bw_revenue_usd_bigint,
            company?.li_followers_count_integer,
            company?.li_employee_count_integer,
          ])}
        </li>
        <li>
          {Tab(t('Funding & Financials'), '#fundingAndFinancials', [
            company?.investors,
            company?.funding_round_count_integer,
            company?.latest_funding_type,
            company?.exit_count_integer,
            company?.total_assets_bigint_usd,
            company?.revenue_bigint_usd,
            company?.net_income_bigint_usd,
            company?.operating_income_bigint_usd,
          ])}
        </li>
        <li>{Tab('Similar Companies', '#similarCompanies', [similarCompanies])}</li>
        <li>{Tab(t('Notes'), '#notes')}</li>
      </ul>
    )
  }

  const insightsData: InsightsDataType = {
    sales_target: company?.sales_target_type,
    revenue_stream: company?.revenue_stream_type,
    industries: company?.industries,
    region: company?.region,
    sub_region: company?.sub_region,
  }

  function Body() {
    return (
      <div className={styles.body}>
        {hash === '' && Basics()}
        {hash === '#keyContacts' && KeyContacts()}
        {hash === '#growth' && Growth()}
        {hash === '#fundingAndFinancials' && FundingAndFinancials()}
        {hash === '#insights' && <Insights insightsObject={insightsData} />}
        {hash === '#similarCompanies' && <SimilarCompanies id={props.id} companies={similarCompanies} />}
        {hash === '#notes' && <CompanyNotes companyId={props.id} />}
      </div>
    )
  }

  function BasicsCard(leftCardWidth: number) {
    return (
      <CompanyDetailsCard title={t('Basics')} style={{ width: `${leftCardWidth}%` }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <CompanyDataPoint label={t('Founded in')} value={year(company?.founded_on_date, 'n/a')} />
          <CompanyDataPoint label={t('Employee Range')} value={rangeString(company?.employee_count_int4range, undefined, 'n/a')} />
          <CompanyDataPoint label={t('Country')} value={flagAndCountryName(company?.country_iso_3166_1_alpha_3, CountryFormat.Code) ?? 'n/a'} />
        </div>
        <CompanyDataPoint label={t('Operating Status')} value={company?.status_enum ? OPERATING_STATUS_MAP[company?.status_enum] : 'n/a'} />
        <CompanyDataPoint label={t('Company Type')} value={liType(company?.li_type_enum, 'n/a')} />
        <CompanyDataPoint label={t('Address')} value={companyFormattedAddress()} />
        {/* <CompanyDataPoint label={'Industries'} value={industries(company?.industries ?? ['n/a'])} /> */}
      </CompanyDetailsCard>
    )
  }

  function LinkedinCard() {
    return (
      <CompanyDetailsCard
        title={'LinkedIn'}
        thingsToDisplay={[
          localization === 'jp' ? company?.name_jp : company?.li_name,
          company?.li_logo_url,
          company?.li_type_enum,
          company?.li_followers_count_integer,
          company?.li_employee_list,
          company?.li_employee_count_integer,
          company?.linkedin_url,
        ]}
        naLinkMessage={'Visit LinkedIn'}
        naLinkUrl={company?.linkedin_url}
        style={{ minHeight: '14em', overflow: 'auto' }}
      >
        {company?.li_name && (
          <>
            <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
              <CbLiOrPlaceholderImage li_logo_url={company.li_logo_url} altText={'Logo'} size={70} style={{ borderRadius: '50%' }} />
              <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                {company?.li_name && <span style={{ fontWeight: '700' }}>{company?.li_name}</span>}
                {company?.li_type_enum != null && (
                  <span>
                    {liType(company?.li_type_enum)} • {internationalUnits(company?.li_followers_count_integer, 1, '', 'n/a')} {t('Followers')}
                  </span>
                )}
                {linkedinUrl && (
                  <a href={linkedinUrl} target="_blank" rel="noopener noreferrer">
                    {linkedinUrl}
                  </a>
                )}
              </div>
            </div>
            {company?.li_employee_list && (
              <>
                <hr style={{ width: '100%', height: '1px', border: 'none', backgroundColor: '#dee3ec' }} />
                <div>
                  <span style={{ marginLeft: '8px', fontWeight: '800' }}>{internationalUnits(company?.li_employee_count_integer, 1, '', 'n/a')}</span>{' '}
                  {t('Employees on LinkedIn')}
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '8px' }}>
                  {company?.li_employee_list?.map((employee, i) => (
                    <LinkedinEmployee
                      key={i}
                      profile_url={employee.profile_url}
                      profile_picture_url={employee.profile_picture_url}
                      role={employee.role}
                      name={employee.name}
                    />
                  ))}
                </div>
                {company?.linkedin_url && (
                  <a
                    href={linkFromUrlOrDomain(company?.linkedin_url)}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textAlign: 'center', width: '100%', display: 'inline-block' }}
                  >
                    {t('View More Employees')}
                  </a>
                )}
              </>
            )}
          </>
        )}
      </CompanyDetailsCard>
    )
  }

  function DescriptionCard() {
    return (
      <CompanyDetailsCard title={t('Description')} style={{ width: '100%', overflow: 'auto', flexGrow: 1 }}>
        <div>
          <Markdown>
            {(withoutHighlight(localization === 'jp' ? company?.description_jp : company?.description) ||
              withoutHighlight(localization === 'jp' ? company?.short_description_jp : company?.short_description)) ??
              'n/a'}
          </Markdown>
        </div>
      </CompanyDetailsCard>
    )
  }

  function ScreenshotCard(leftCardWidth: number) {
    const linkMessage = t('Visit Website')
    return (
      <CompanyDetailsCard
        thingsToDisplay={[company?.website_screenshot_id]}
        naLinkMessage={linkMessage}
        naLinkUrl={link}
        naTitle={'Screenshot'}
        flat={true}
        style={{
          position: 'relative',
          height: '30em',
          width: '100%',
          // width: `calc(${100 - leftCardWidth}% - 16px)`,
          // left: `calc(${leftCardWidth}% + 16px)`,
        }}
      >
        <a href={link} title={linkMessage} target="_blank" rel="noopener noreferrer" style={{ width: '100%', height: '100%', display: 'block' }}>
          <img
            src={'https://kurrant-ai-vacuum-images.s3.amazonaws.com/vacuum/screenshots/' + encodeS3URI(company?.website_screenshot_id)}
            alt={'Screenshot'}
            style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'top' }}
          />
        </a>
      </CompanyDetailsCard>
    )
  }

  function Basics() {
    const leftCardWidth = 42
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <div style={{ display: 'flex', gap: '16px', position: 'relative' }}>
          {BasicsCard(leftCardWidth)}
          {CompanyNewsCard(leftCardWidth, news)}
          {/* {ScreenshotCard(leftCardWidth)} */}
        </div>
        <div style={{ display: 'flex', gap: '16px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', flex: 1 }}>
            {LinkedinCard()}
            <InstagramCard company={company} />
          </div>
          <div style={{ flex: '0 0 50%', display: 'flex', flexDirection: 'column' }}>{DescriptionCard()}</div>
        </div>
      </div>
    )
  }

  function KeyContacts() {
    return (
      <Masonry breakpointCols={3} className="key-contacts-grid" columnClassName="key-contacts-grid-column">
        {company?.people?.map((employee, i) => (
          <KeyContact key={i} {...employee} />
        ))}
      </Masonry>
    )
  }

  function Growth() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <CompanyDetailsCard
          title={t('Growth')}
          thingsToDisplay={[company?.tech_spent_bigint_usd, company?.ig_followers_count_integer]}
          naLinkMessage={'Visit LinkedIn'}
          naLinkUrl={company?.linkedin_url}
        >
          <div style={{ display: 'grid', columnGap: '16px', gridTemplateColumns: 'repeat(3, 33%)' }}>
            <CompanyDataPoint label={t('Tech Spend')} value={internationalUnits(company?.tech_spent_bigint_usd, 1, '$', 'n/a')} />
            <CompanyDataPoint label={`Instagram ${t('Followers')}`} value={internationalUnits(company?.ig_followers_count_integer, 1, '', 'n/a')} />
          </div>
        </CompanyDetailsCard>
        <CompanyDetailsCard
          thingsToDisplay={[company?.bw_revenue_usd_bigint, company?.li_followers_count_integer, company?.li_employee_count_integer]}
          naTitle={'Graph'}
          naLinkMessage={'Visit LinkedIn'}
          naLinkUrl={company?.linkedin_url}
        >
          <div style={{ display: 'grid', columnGap: '16px', gridTemplateColumns: 'repeat(3, 33%)' }}>
            <Latest6MonthsChart
              label={t('Estimated Sales Revenue')}
              latestValue={company?.bw_revenue_usd_bigint}
              momValue={company?.bw_revenue_mom_decimal}
              values={company?.bw_revenue_latest_6_months}
              unit={'$'}
            />
            <Latest6MonthsChart
              label={`Linkedin ${t('Followers')}`}
              latestValue={company?.li_followers_count_integer}
              momValue={company?.li_followers_count_mom_decimal}
              values={company?.li_followers_count_latest_6_months}
            />
            <Latest6MonthsChart
              label={`Linkedin ${t('Employees')}`}
              latestValue={company?.li_employee_count_integer}
              momValue={company?.li_employee_count_mom_decimal}
              values={company?.li_employee_count_latest_6_months}
            />
          </div>
        </CompanyDetailsCard>
      </div>
    )
  }

  function FundingAndFinancials() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <CompanyDetailsCard
          title={t('Financials')}
          thingsToDisplay={[
            company?.total_assets_bigint_usd,
            company?.net_income_bigint_usd,
            company?.revenue_bigint_usd,
            company?.operating_income_bigint_usd,
          ]}
        >
          <div style={{ display: 'grid', columnGap: '16px', gridTemplateColumns: 'repeat(4, 25%)' }}>
            <CompanyDataPoint label={t('Total Assets')} value={internationalUnits(company?.total_assets_bigint_usd, 1, '$', 'n/a')} />
            <CompanyDataPoint label={t('Net Income')} value={internationalUnits(company?.net_income_bigint_usd, 1, '$', 'n/a')} />
            <CompanyDataPoint label={t('Revenue')} value={internationalUnits(company?.revenue_bigint_usd, 1, '$', 'n/a')} />
            <CompanyDataPoint label={t('Operating Income')} value={internationalUnits(company?.operating_income_bigint_usd, 1, '$', 'n/a')} />
          </div>
        </CompanyDetailsCard>

        <CompanyDetailsCard
          title={t('Funding')}
          thingsToDisplay={[company?.latest_funding_type, company?.latest_funding_amount_bigint, company?.latest_funding_on_date, company?.exit_count_integer]}
        >
          <div style={{ display: 'grid', columnGap: '16px', gridTemplateColumns: 'repeat(4, 25%)' }}>
            <CompanyDataPoint label={t('Total Funding')} value={totalFundingRecap(company)} />
            <CompanyDataPoint
              label={t('Latest Funding')}
              value={
                (company?.latest_funding_type ? FUNDING_TYPES[company?.latest_funding_type] : '') +
                (company?.latest_funding_type && company?.latest_funding_amount_bigint ? ' - ' : '') +
                internationalUnits(company?.latest_funding_amount_bigint, 1, '$') +
                ((company?.latest_funding_type || company?.latest_funding_amount_bigint) && company?.latest_funding_on_date ? ' in ' : '') +
                year(company?.latest_funding_on_date) +
                (!company?.latest_funding_type && !company?.latest_funding_amount_bigint && !company?.latest_funding_on_date ? 'n/a' : '')
              }
            />
            <CompanyDataPoint label={t('Exit Round')} value={company?.exit_count_integer?.toString() ?? 'n/a'} />
          </div>
          <CompanyDataPoint label={t('Investors')}>
            <ul
              style={{
                display: 'grid',
                columnGap: '16px',
                gridTemplateColumns: 'repeat(4, 25%)',
                listStyle: 'inside',
                padding: '0',
                margin: '0',
              }}
            >
              {company?.investors?.split(', ')?.map((inv, i) => <li key={i}>{inv}</li>) ?? 'n/a'}
            </ul>
          </CompanyDataPoint>
        </CompanyDetailsCard>
      </div>
    )
  }

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <div style={{ width: 'calc(100% - var(--right-sidebar-width))' }}>
        <TopBar breadcrumbs={breadcrumbs} />
        <div style={{ height: 'calc(100% - 50px)', overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
          {Header()}
          {Tabs()}
          {Body()}
        </div>
      </div>
      <RightSidebar api={props.cache?.api} company={company} />
    </div>
  )
}

type TabFragment = '' | '#keyContacts' | '#growth' | '#fundingAndFinancials' | '#insights' | '#similarCompanies' | '#notes'

export default withNamespaces()(CompanyPage)
