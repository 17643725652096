import React, { useCallback, useEffect, useState } from 'react'
import styles from '../style.module.scss'
import QuickSearchInput from './QuickSearchInput'
import { getQuickSearch } from '../../../../logic/Backend'
import debounce from 'lodash/debounce'
import { showErrorToast } from '../../../../logic/Toaster'
import AutoSuggest from './AutoSuggest'
import { t } from 'i18next'
interface Props {
  sendMessage: (message: string) => void
  onSuggestedConversationClick: (text: string) => void
}

export type QuickSearchCompaniesType = {
  domain: string
  id: string
  industry_tag?: string
  logo_id?: string
  name: string
  name_jp?: string
}

function SearchBar(props: Props) {
  const { sendMessage, onSuggestedConversationClick } = props
  const [loading, setLoading] = useState(false)
  const [quickSearchInput, setQuickSearchInput] = useState('')
  const [autosuggestItems, setAutosuggestItems] = useState<QuickSearchCompaniesType[]>([])
  const [from, setFrom] = useState(0)
  const [inputForGetRequest, setInputForGetRequest] = useState('')
  const [noReturnedCompanies, setNoRetunedCompanies] = useState(false)
  const [conversationInitiated, setConversationInitiated] = useState(false)
  const getAutocompleteResults = useCallback(
    debounce(async (input: string) => {
      setLoading(true)

      if (input === '') {
        setAutosuggestItems([])
        return
      }
      try {
        const controller = new AbortController()
        const signal = controller.signal
        if (from === 0 && inputForGetRequest === input) {
          setLoading(false)
          return
        }

        const data = await getQuickSearch({ query: input, from: inputForGetRequest === input ? from : 0, limit: 10 }, signal)
        if (conversationInitiated) {
          controller.abort()
          setConversationInitiated(false)
        }
        setInputForGetRequest(input)
        inputForGetRequest !== input && setFrom(0)
        setAutosuggestItems((prevState) => {
          if (data.companies.length === 0) {
            setNoRetunedCompanies(true)
          } else {
            setNoRetunedCompanies(false)
          }
          return inputForGetRequest === input ? [...prevState, ...data.companies] : data.companies
        })
        setLoading(false)
      } catch (e) {
        showErrorToast('Error fetching company data')
        console.log(e)
      }
    }, 1000),
    [from, conversationInitiated]
  )
  useEffect(() => {
    quickSearchInput !== '' && getAutocompleteResults(quickSearchInput)
  }, [quickSearchInput, getAutocompleteResults, from])

  return (
    <div className={styles.searchBarContainer}>
      <QuickSearchInput
        onSuggestedConversationClick={onSuggestedConversationClick}
        heading={<p className={styles.inputHeading}>{t('Ask anything about startups, news, or trends')}</p>}
        input={
          <AutoSuggest
            getQuickSearchSuggestions={setQuickSearchInput}
            searchResultCompanies={autosuggestItems}
            loading={loading}
            setFrom={setFrom}
            noReturnedCompanies={noReturnedCompanies}
            sendMessage={sendMessage}
            onEnter={() => setConversationInitiated(true)}
          />
        }
      />
    </div>
  )
}

export default SearchBar
