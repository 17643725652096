import React, { Dispatch, FC, ReactElement, SetStateAction, useEffect, useState } from 'react'
import FiltersPanel, { FiltersPanelProps } from './FiltersPanel'
import { Button, Dialog, Divider, Icon, InputGroup, IPopoverProps, Label, Menu, MenuDivider, MenuItem, Popover, Checkbox } from '@blueprintjs/core'
import { createSavedFilter, deleteSavedFilter, SavedFilterData, updateSavedFilter } from '../../logic/Backend'
import { showErrorToast, showSuccessToast } from '../../logic/Toaster'
import { SessionStorage } from '../../logic/ClientSideStorage'
import { MultiSelectState } from './Filters/MultiSelect'
import { NumberRangePickerState } from './Filters/NumberRangePicker'
import { DebouncedInputGroupState } from '../../ui/components/DebounchedInputGroup'
import ahoy, { EventProperties } from 'ahoy.js'
import { ServerSideDatasource } from '../../logic/AgGrid/AgGrid'
import { GridApi } from '@ag-grid-community/core'
import { FilterModel } from '../../ui/components/Filters'
import equal from 'fast-deep-equal/es6'
import { ButtonShowingSelection } from '../ButtonShowingSelection'
import palette from '../../_palette.module.scss'
import { queryIndex } from '../../logic/Helpers'
import { highlightText } from '../../logic/ValueFormatters'
import { Tooltip } from '../Tooltip'
import { Trans, withNamespaces, WithNamespaces } from 'react-i18next'
import AlertToggleButton from './AlertToggleButton'

export interface SavedFiltersPanelProps {
  savedFilters: SavedFilterData[]
  activeSavedFilterId?: SavedFilterData['id']
  backendCreateSavedFilter: typeof createSavedFilter
  backendUpdateSavedFilter: typeof updateSavedFilter
  backendDeleteSavedFilter: typeof deleteSavedFilter
  gridApi?: GridApi
  sessionStorage?: SessionStorage
  incomingSortModel?: Array<{ colId: string; sort: 'asc' | 'desc' }>
}

const SavedFiltersPanel: FC<SavedFiltersPanelProps & WithNamespaces & IncludeT> = ({
  savedFilters,
  activeSavedFilterId,
  backendCreateSavedFilter,
  backendUpdateSavedFilter,
  backendDeleteSavedFilter,
  gridApi,
  sessionStorage,
  incomingSortModel,
  t,
}) => {
  const allCompaniesLabel = t('All Companies')
  const emptyUnsavedFilters = { id: 'unsaved', filter_model: [] }

  const [firstRender, setFirstRender] = useState(false)
  const [isOpenSavedFiltersPanel, setIsOpenSavedFiltersPanel] = useState(false)
  const [isOpenFiltersPanel, setIsOpenFiltersPanel] = useState(false)
  const [isOpenSaveAsDialog, setIsOpenSaveAsDialog] = useState(false)
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false)
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false)
  const [savedFiltersProp, setSavedFiltersProp] = useState(savedFilters)
  const [savedFilters$, setSavedFilters$] = useState(savedFilters.map((a) => ({ ...a })))
  const [activeSavedFilterId$, setActiveSavedFilterId$] = useState<SavedFilterData['id'] | undefined>(
    activeSavedFilterId ?? sessionStorage?.loadActiveSavedFilterId()
  )
  const [toDeleteSavedFilterId$, setToDeleteSavedFilterId$] = useState<SavedFilterData['id']>()
  const [toEditSavedFilterId$, setToEditSavedFilterId$] = useState<SavedFilterData['id']>()
  const [unsavedFilters, setUnsavedFilters] = useState<SavedFilterData>({ ...emptyUnsavedFilters })
  const [saveAsTitle, setSaveAsTitle] = useState<string>()
  const [editTitle, setEditTitle] = useState<string>()
  const [saveAsLoading, setSaveAsLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [editLoading, setEditLoading] = useState(false)
  const [updateExistingLoading, setUpdateExistingLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [presetFilterTooltipIsOpen, setPresetFilterTooltipIsOpen] = useState(false)
  const [alertStatus, setAlertStatus] = useState<boolean>(false)

  const activeSavedFilter = savedFilters$.find((sf) => sf.id === activeSavedFilterId$)
  const filterCount = (activeSavedFilter ?? unsavedFilters).filter_model!.length
  const unsavedUpdates = (() => {
    if (activeSavedFilter) {
      const initialSavedFilter = savedFiltersProp.find((sf) => sf.id === activeSavedFilterId$)!
      return !equal(initialSavedFilter?.filter_model ?? [], activeSavedFilter?.filter_model ?? [])
    } else {
      return unsavedFilters.filter_model!.length > 0
    }
  })()

  useEffect(() => {
    if (!firstRender) {
      setFirstRender(true)
      assignFilterModel((activeSavedFilter ?? unsavedFilters).filter_model)
    }
    return () => (ServerSideDatasource.filterModel = undefined)
  }, [])

  useEffect(() => {
    if (incomingSortModel && incomingSortModel.length > 0 && gridApi) {
      gridApi.setSortModel(incomingSortModel)

      gridApi.refreshServerSideStore({ purge: true })
      ahoy.track('applySort', { column: incomingSortModel[0].colId, sort: incomingSortModel[0].sort })
    }
  }, [incomingSortModel, gridApi])

  function submitSearch(filter_model?: FilterModel) {
    filter_model?.forEach((filter: any) => {
      if (filter?.state?.selectedNestedIndustryNames?.length === 0) {
        filter.state.selectedNestedIndustryNames = undefined
      }
    })
    assignFilterModel(filter_model)
  }

  const onChangeFiltersPanel: FiltersPanelProps['onChange'] = (filter_model) => {
    setUnsavedFilters({ ...unsavedFilters })
    if (!equal((activeSavedFilter ?? unsavedFilters).filter_model ?? [], filter_model)) {
      if (activeSavedFilter) {
        activeSavedFilter.filter_model = filter_model
        setSavedFilters$(savedFilters$.map((a) => ({ ...a })))
      } else {
        unsavedFilters.filter_model = filter_model
        setUnsavedFilters({ ...unsavedFilters })
      }
    }
  }

  function assignFilterModel(fm?: FilterModel) {
    ServerSideDatasource.filterModel = fm?.map((f) => ({ ...f }))
    gridApi?.onFilterChanged()
  }

  async function saveAs(): Promise<void> {
    try {
      setSaveAsLoading(true)
      const newSavedFilter = { ...(activeSavedFilter ?? unsavedFilters), title: saveAsTitle, active_alert: alertStatus ?? false } as SavedFilterData
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newSavedFilter.id && delete newSavedFilter.id
      const body = cleanForBackend(newSavedFilter)
      ahoy.track('newSavedFilter', body as unknown as EventProperties)
      const newSavedFilterWithId = await backendCreateSavedFilter(body)
      setIsOpenSaveAsDialog(false)
      const newFilters = [newSavedFilterWithId, ...savedFiltersProp.map((a) => ({ ...a }))]
      setSavedFilters$(newFilters)
      setSavedFiltersProp(newFilters.map((a) => ({ ...a })))
      setActiveSavedFilterId$(newSavedFilterWithId.id)
      if (alertStatus) {
        showSuccessToast(
          `<strong>${newSavedFilter.title}</strong> ${t(
            'has been saved. You will receive an email alert once new organizations have been added to the platform.'
          )}`
        )
      }
      if (!activeSavedFilter) {
        setUnsavedFilters({ ...emptyUnsavedFilters })
      }
    } catch (error) {
      if (error.message.match(/status: (\d+)/)?.[1] === '417') {
        showErrorToast(t('This name is already taken'))
      } else {
        showErrorToast(t('Failed to create Saved Filter'))
      }
    } finally {
      setSaveAsLoading(false)
      setAlertStatus(false)
    }
  }

  async function onUpdateFiltersPanel(filterId: string, shouldToggleAlert: boolean): Promise<void> {
    try {
      const savedFilterToUpdate = savedFilters$.find((filter) => filter.id === filterId)
      if (!savedFilterToUpdate) throw new Error('Filter not found')
      if (shouldToggleAlert) {
        savedFilterToUpdate.active_alert = !savedFilterToUpdate.active_alert
      }
      await backendUpdateSavedFilter(cleanForBackend(savedFilterToUpdate))
      setSavedFiltersProp(savedFilters$.map((a) => ({ ...a })))
      setUpdateExistingLoading(false)

      const undoFunction = async () => {
        savedFilterToUpdate.active_alert = !savedFilterToUpdate.active_alert
        await backendUpdateSavedFilter(cleanForBackend(savedFilterToUpdate))
        setSavedFiltersProp(savedFilters$.map((a) => ({ ...a })))
      }

      if (savedFilterToUpdate.active_alert && shouldToggleAlert) {
        showSuccessToast(`You have turned <strong>on</strong> email alerts for the Filter <strong>${savedFilterToUpdate.title}</strong> `, undoFunction)
      } else if (!savedFilterToUpdate.active_alert && shouldToggleAlert) {
        showErrorToast(`You have turned <strong>off</strong> email alerts for the Filter <strong>${savedFilterToUpdate.title}</strong> `, undoFunction)
      }
    } catch (error) {
      showErrorToast(t('Failed to update Saved Filter'))
    } finally {
      setUpdateExistingLoading(false)
    }
  }

  function cleanForBackend(savedFilterData: SavedFilterData): SavedFilterData {
    savedFilterData.filter_model = savedFilterData.filter_model!.filter(({ filterType, state }) => {
      if (filterType === 'set') {
        state = state as MultiSelectState
        return state.selectedNestedIndustryNames
          ? state.selectedNestedIndustryNames && state.selectedNestedIndustryNames.length > 0
          : state.selectedItems && state.selectedItems.length > 0
      }
      if (filterType === 'text') {
        state = state as DebouncedInputGroupState
        return state.text && state.text.length > 0
      }
      if (filterType === 'numberRange') {
        state = state as NumberRangePickerState
        return state.start !== undefined || state.end !== undefined
      }
      return true
    })
    return savedFilterData
  }

  function onResetFiltersPanel(): void {
    setSavedFilters$(savedFiltersProp.map((a) => ({ ...a })))
  }

  function buttonLabel(): string {
    if (filterCount > 0) {
      let label = `${filterCount} Filter${filterCount > 1 ? 's' : ''} Applied`
      if (unsavedUpdates) {
        label += ` ${t('Unsaved Changes')}`
      }
      return label
    }
    return t('Advanced Filters')
  }

  function onClickSavedFilter(id?: SavedFilterData['id']) {
    setActiveSavedFilterId$(id)
    sessionStorage?.saveActiveSavedFilterId(id)
    const fm = id ? savedFilters$.find((sf) => sf.id === id)! : unsavedFilters
    assignFilterModel(fm.filter_model)
  }

  function onClickDeleteSavedFilter(id: SavedFilterData['id'], event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()
    setToDeleteSavedFilterId$(id)
    setIsOpenDeleteDialog(true)
  }

  async function onClickDeleteSavedFilterConfirm() {
    try {
      setDeleteLoading(true)
      const body = { id: toDeleteSavedFilterId$! }
      ahoy.track('deleteSavedFilter', body as EventProperties)
      await backendDeleteSavedFilter?.(body)
      if (toDeleteSavedFilterId$ === activeSavedFilterId$) {
        onClickSavedFilter(undefined)
      }
      const newFilters = savedFilters$.filter((sf) => sf.id !== toDeleteSavedFilterId$)
      setSavedFilters$(newFilters)
      setSavedFiltersProp(newFilters.map((a) => ({ ...a })))
      setIsOpenDeleteDialog(false)
    } catch (error) {
      showErrorToast(t('Failed to delete Saved Filter'))
    } finally {
      setDeleteLoading(false)
    }
  }

  function onClickEditSavedFilter(id: SavedFilterData['id'], event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation()
    setToEditSavedFilterId$(id)
    setEditTitle(savedFilters$.find((sf) => sf.id === id)?.title)
    setIsOpenEditDialog(true)
  }

  async function onClickEditSavedFilterConfirm() {
    try {
      setEditLoading(true)
      const editedFilter = savedFilters$.find((sf) => sf.id === toEditSavedFilterId$)!
      editedFilter.title = editTitle
      ahoy.track('editSavedFilter', editedFilter as EventProperties)
      await backendUpdateSavedFilter?.(editedFilter)
      setSavedFilters$(savedFilters$)
      setSavedFiltersProp(savedFilters$.map((a) => ({ ...a })))
      setIsOpenEditDialog(false)
    } catch (error) {
      showErrorToast(t('Failed to edit Saved Filter'))
    } finally {
      setEditLoading(false)
    }
  }

  function EditDialog(): ReactElement {
    return (
      <Dialog style={{ display: 'flex', flexDirection: 'column', padding: '20px', width: '400px' }} isOpen={isOpenEditDialog}>
        <Label>
          <strong>Saved Filter Title</strong>
          <InputGroup autoFocus={true} value={editTitle} onChange={(e) => setEditTitle(e.currentTarget.value)} />
        </Label>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button disabled={editLoading} style={{ marginLeft: 'auto' }} text={t('Cancel')} onClick={() => setIsOpenEditDialog(false)} />
          <Button loading={editLoading} type={'submit'} text={t('Rename Saved Filter')} intent={'primary'} onClick={onClickEditSavedFilterConfirm} />
        </div>
      </Dialog>
    )
  }

  function DeleteDialog(): ReactElement {
    return (
      <Dialog style={{ display: 'flex', flexDirection: 'column', padding: '20px', width: '400px', gap: '20px' }} isOpen={isOpenDeleteDialog}>
        <strong>
          {t('Delete Saved Filter')} <i>{savedFilters$.find((sf) => sf.id === toDeleteSavedFilterId$)?.title}</i>?
        </strong>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button disabled={deleteLoading} style={{ marginLeft: 'auto' }} text={t('Cancel')} onClick={() => setIsOpenDeleteDialog(false)} />
          <Button autoFocus={true} loading={deleteLoading} type={'submit'} text={t('Delete')} intent={'primary'} onClick={onClickDeleteSavedFilterConfirm} />
        </div>
      </Dialog>
    )
  }

  function SaveAsDialog(): ReactElement {
    return (
      <Dialog style={{ display: 'flex', flexDirection: 'column', padding: '20px', width: '400px' }} isOpen={isOpenSaveAsDialog}>
        <Label>
          <strong>{t('Saved Filter Title')}</strong>
          <InputGroup autoFocus={true} value={saveAsTitle} onChange={(e) => setSaveAsTitle(e.currentTarget.value)} />
        </Label>
        <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
          <Checkbox onChange={() => setAlertStatus(!alertStatus)}>Alert me for new companies</Checkbox>
          <div>
            <Button disabled={saveAsLoading} style={{ marginLeft: 'auto' }} text={t('Cancel')} onClick={() => setIsOpenSaveAsDialog(false)} />
            <Button loading={saveAsLoading} style={{ marginLeft: '10px' }} type={'submit'} text={t('Save new filter')} intent={'primary'} onClick={saveAs} />
          </div>
        </div>
      </Dialog>
    )
  }

  function SavedFiltersPanelPopover(search: string, setSearch: Dispatch<SetStateAction<string>>): ReactElement {
    return (
      <Popover
        modifiers={offsetPopoverBy50px}
        position={'bottom'}
        isOpen={isOpenSavedFiltersPanel}
        onInteraction={(open) => {
          if (open) {
            setPresetFilterTooltipIsOpen(false)
          }
          setIsOpenSavedFiltersPanel(open)
        }}
      >
        <Tooltip
          enforceFocus={false}
          usePortal={false}
          isOpen={presetFilterTooltipIsOpen}
          onInteraction={(open) => setPresetFilterTooltipIsOpen(open && !isOpenSavedFiltersPanel)}
        >
          <ButtonShowingSelection
            style={{ maxWidth: '370px' }}
            text={activeSavedFilter?.title ?? allCompaniesLabel}
            {...(activeSavedFilter?.title && { icon: <Icon icon={'bookmark'} color={palette.blue3} /> })}
          />
          <div>{t('Preset Filter')}</div>
        </Tooltip>
        <Menu>
          <MenuItem
            text={
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <div style={{ fontWeight: activeSavedFilterId$ ? 'normal' : 'bold' }}>{allCompaniesLabel}</div>
                <i style={{ marginLeft: 'auto' }}>{t('Default')}</i>
              </div>
            }
            onClick={() => onClickSavedFilter(undefined)}
          />
          <MenuDivider />
          {savedFilters$.length === 0 ? (
            <div style={{ display: 'flex', flexDirection: 'column', padding: '10px 7px 0 7px', gap: '10px', whiteSpace: 'pre-line' }}>
              <p style={{ fontSize: '12px', fontWeight: 'bold', fontVariant: 'all-small-caps', color: '#b8b8b8' }}>{t('My Saved Filters')}</p>
              <p>
                <Trans i18nKey="no_saved_filters">
                  You don’t have any <strong>Saved Filters</strong>
                </Trans>
              </p>
              <p>
                <Trans i18nKey="no_saved_filters_cont">
                  Start saving filters by selected them from <strong>Advanced Filters</strong> above and entering a name
                </Trans>
              </p>
              <Button
                intent={'primary'}
                text={t('Create Saved Filter')}
                onClick={() => {
                  setIsOpenSavedFiltersPanel(false)
                  setIsOpenFiltersPanel(true)
                }}
              />
            </div>
          ) : (
            <>
              <div style={{ padding: '5px 7px' }}>
                <p style={{ fontSize: '12px', fontWeight: 'bold', fontVariant: 'all-small-caps', color: '#b8b8b8' }}>{t('My Saved Filters')}</p>
                <InputGroup
                  leftIcon={'search'}
                  placeholder={t('Search Saved Filter')}
                  fill={true}
                  value={search}
                  onChange={(v) => setSearch(v.currentTarget.value)}
                />
              </div>
              {savedFilters$
                .filter((sf) => search === '' || queryIndex(sf.title!, search) !== -1)
                .map((sf, i) => (
                  <MenuItem
                    key={i}
                    onClick={() => onClickSavedFilter(sf.id)}
                    text={
                      <div
                        style={{
                          display: 'flex',
                          maxWidth: '400px',
                          gap: '5px',
                          alignItems: 'center',
                          fontWeight: sf.id === activeSavedFilterId$ ? '600' : 'normal',
                        }}
                      >
                        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} title={sf.title}>
                          {highlightText(sf.title!, search)}
                        </span>
                        <Button
                          small={true}
                          icon={'edit'}
                          style={{ marginLeft: 'auto' }}
                          onClick={(e: React.MouseEvent<HTMLElement>) => onClickEditSavedFilter(sf.id, e)}
                        />
                        <Button small={true} icon={'trash'} onClick={(e: React.MouseEvent<HTMLElement>) => onClickDeleteSavedFilter(sf.id, e)} />
                        <AlertToggleButton
                          savedFilter={sf}
                          onClick={(id, shouldToggleAlert) => {
                            if (shouldToggleAlert) {
                              onUpdateFiltersPanel(id, shouldToggleAlert)
                            }
                          }}
                        />
                      </div>
                    }
                  />
                ))}
            </>
          )}
        </Menu>
      </Popover>
    )
  }
  function FiltersPanelPopover(): ReactElement {
    return (
      <Popover
        modifiers={offsetPopoverBy50px}
        popoverClassName={'bp3-large-popover'}
        position={'bottom'}
        isOpen={isOpenFiltersPanel}
        onInteraction={(open) => setIsOpenFiltersPanel(open)}
      >
        <ButtonShowingSelection style={{ maxWidth: '280px' }} intent={filterCount > 0 ? 'primary' : 'none'} text={buttonLabel()} icon={'filter'} />
        <>
          <FiltersPanel filterModel={(activeSavedFilter ?? unsavedFilters)?.filter_model} onChange={onChangeFiltersPanel} />
          <div style={{ display: 'flex', gap: '10px', justifyContent: 'end', paddingTop: '10px' }}>
            <Button
              disabled={filterCount === 0 || !unsavedUpdates || updateExistingLoading}
              text={t('Create New Saved Filter…')}
              intent={!unsavedUpdates || !activeSavedFilter ? 'primary' : 'none'}
              onClick={() => setIsOpenSaveAsDialog(true)}
            />
            {!(activeSavedFilter && unsavedUpdates) && (
              <Button
                text={t('Submit')}
                intent={!unsavedUpdates || !activeSavedFilter ? 'primary' : 'none'}
                onClick={() => {
                  submitSearch(activeSavedFilter?.filter_model ?? unsavedFilters.filter_model)
                  setIsOpenFiltersPanel(false)
                }}
                disabled={filterCount === 0}
              />
            )}
            {activeSavedFilter && unsavedUpdates && (
              <>
                <Button text={t('Revert Changes')} onClick={onResetFiltersPanel} disabled={updateExistingLoading} />
                <Button
                  text={t('Update Saved Filter')}
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.preventDefault()
                    if (activeSavedFilter && activeSavedFilter.id) {
                      onUpdateFiltersPanel(activeSavedFilter.id, false)
                    }
                  }}
                  loading={updateExistingLoading}
                />
                <Divider />
                <Button
                  text={t('Submit')}
                  intent={'primary'}
                  onClick={() => {
                    submitSearch(activeSavedFilter?.filter_model ?? unsavedFilters.filter_model)
                    setIsOpenFiltersPanel(false)
                  }}
                  disabled={filterCount === 0 || updateExistingLoading}
                />
              </>
            )}
          </div>
        </>
      </Popover>
    )
  }

  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      {SavedFiltersPanelPopover(search, setSearch)}
      {FiltersPanelPopover()}
      {isOpenSaveAsDialog && SaveAsDialog()}
      {isOpenDeleteDialog && DeleteDialog()}
      {isOpenEditDialog && EditDialog()}
    </div>
  )
}

const offsetPopoverBy50px: IPopoverProps['modifiers'] = {
  offset: {
    enabled: true,
    fn: (data) => {
      data.offsets.popper.left = 55
      return data
    },
  },
}

export default withNamespaces()(SavedFiltersPanel)
