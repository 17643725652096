import { Checkbox, MenuItem, Menu } from '@blueprintjs/core'
import { IItemModifiers } from '@blueprintjs/select'
import React, { useState } from 'react'
import { IndustryGroup, NestedIndustry } from '../../../../ui/components/MockIndustry'
import { useSelector } from 'react-redux'
import css from '../MultiSelect.module.scss'
import { SelectedIndustryGroups } from '../MultiSelect'

interface Props {
  modifiers: IItemModifiers
  selectedItems: string[]
  index?: number
  item: string
  handleClick: React.MouseEventHandler<HTMLElement>
  onNestedItemSelect: (items: any, hasIndustry?: boolean, hasSubIndustry?: boolean) => void
  industryState: IndustryGroup[] // comes from global state, passed as prop
  selectedIndustryObjects?: any
  getTranslation: (value: string) => string
}

const NestedIndustries: React.FC<Props> = ({ modifiers, index, item, onNestedItemSelect, industryState, selectedIndustryObjects, getTranslation }) => {
  // update checked based on multiselect state...
  const selectedIndustries = useSelector((state: any) => state.selectedNestedIndustryState.allItems)
  const nestedClick = (industryGroup: string, industry?: string, subIndustry?: string) => {
    const filters: SelectedIndustryGroups[] = []
    // find IG ID
    const industryGroupObject = industryState.find((ig) => ig.name === industryGroup)
    industryGroup &&
      industryGroupObject &&
      filters.push({ id: industryGroupObject?.id, name: industryGroupObject?.name, industries: industryGroupObject?.industries })

    const industryObject: NestedIndustry = industryGroupObject?.industries.find((ind) => ind.name === industry)
    industry && filters.push({ name: industryObject.name, subIndustries: industryObject.subIndustries })

    const subIndustryObject = industryObject?.subIndustries?.find((si) => si.name === subIndustry)
    subIndustry && subIndustryObject && filters.push(subIndustryObject)
    onNestedItemSelect(filters)
  }
  // necessary to avoid click conflict from the MenuItem component using nestedClick
  const disableCheckboxClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
  }
  const industryGroupObject = industryState.find((ig) => ig.name === item)
  return (
    <MenuItem
      active={modifiers.active}
      icon={<Checkbox checked={!!selectedIndustries.find((ig: any) => ig?.id === industryGroupObject?.id)} onClick={(e) => disableCheckboxClick(e)} />}
      disabled={modifiers.disabled}
      key={index}
      onClick={() => nestedClick(item)}
      text={getTranslation(item)}
      shouldDismissPopover={false}
      className={`${css.menuItem} ${modifiers.active && css.menuItemActive}`}
      intent={'success'} // temporary way to customize menuItem BG
      popoverProps={{
        hoverCloseDelay: 500,
      }}
    >
      <Menu>
        {industryState.map((industryGroup: IndustryGroup) => {
          if (industryGroup.name === item) {
            return (
              <React.Fragment key={industryGroup.id}>
                {industryGroup.industries.map((industry: NestedIndustry) => (
                  <MenuItem
                    popoverProps={{
                      hoverCloseDelay: 500,
                    }}
                    key={industry.name}
                    text={getTranslation(industry.name)}
                    icon={
                      <Checkbox
                        checked={
                          !!selectedIndustries.find((inds: any) => inds?.name === industry.name && inds.subIndustries?.[0] === industry.subIndustries?.[0])
                        }
                        onClick={(e) => disableCheckboxClick(e)}
                      />
                    }
                    disabled={modifiers.disabled}
                    onClick={() => nestedClick(item, industry.name)} // handleClick is listening to `item`, not the subset of `item`
                    shouldDismissPopover={false}
                    className={css.menuItem}
                  >
                    {industry.subIndustries.map((subIndustry: any) => {
                      return (
                        <MenuItem
                          key={subIndustry.id}
                          text={getTranslation(subIndustry.name)}
                          icon={
                            <Checkbox checked={!!selectedIndustries.find((obj: any) => obj?.id === subIndustry.id)} onClick={(e) => disableCheckboxClick(e)} />
                          }
                          disabled={modifiers.disabled}
                          onClick={() => nestedClick(item, industry.name, subIndustry.name)}
                          shouldDismissPopover={false}
                          className={css.menuItem}
                        />
                      )
                    })}
                  </MenuItem>
                ))}
              </React.Fragment>
            )
          }
        })}
      </Menu>
    </MenuItem>
  )
}

export default NestedIndustries
