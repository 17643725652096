import React, { FC, useState, useEffect } from 'react'
import ContainerComponent from '../components/ContainerComponent'
import styles from '../components/styles.module.scss'
import { useFetchCompanies } from '../components/useFetchCompanies'
import CompanyItem from '../components/CompanyItem'
import { getHotCompanies } from '../../../../logic/Backend'
import { Icon } from '@blueprintjs/core'
import Skeleton from 'react-loading-skeleton'
import { t } from 'i18next'

export const HotCompanies: FC = () => {
  const { visibleCompanies, loading } = useFetchCompanies(0, 10, getHotCompanies)
  const [initialLoading, setInitialLoading] = useState(true)
  const [paginationLoading, setPaginationLoading] = useState(false)

  useEffect(() => {
    if (!loading) {
      setInitialLoading(false)
      setPaginationLoading(false)
    }
  }, [loading])

  const sortedVisibleCompanies = visibleCompanies
    .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
    .sort((a, b) => b.recommended_score - a.recommended_score)

  const renderedItems = (
    <div className={styles.scrollable}>
      {initialLoading ? (
        <Skeleton count={8} height={80} />
      ) : (
        sortedVisibleCompanies.map((company) => (
          <CompanyItem
            key={company.id}
            company={company}
            additionalInfo={
              <div className={styles.companies__growth_metric}>
                <div className={styles.companies__growth_metric_item}>
                  <span>{Math.round(company.growth * 100)}%</span>
                  <span className={styles.companies__growth_metric_icon}>
                    <Icon icon="chart" />
                  </span>
                </div>
                <div className={styles.companies__growth_metric_item}>
                  <span>{(Number(company.employee_growth) * 100).toFixed(2)}</span>
                  <span className={styles.companies__growth_metric_icon}>
                    <Icon icon={Number(company.employee_growth) < 0 ? 'person' : 'new-person'} />
                  </span>
                </div>
              </div>
            }
          />
        ))
      )}
      {paginationLoading && <Skeleton count={1} height={80} />}
    </div>
  )

  return (
    <div className={styles.fullWidthContainer}>
      <h2 className={styles.header}>{t('Hot Companies')}</h2>
      <ContainerComponent renderedItems={renderedItems} />
    </div>
  )
}

export default HotCompanies
