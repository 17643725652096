import React, { useEffect, useRef } from 'react'
import QuickSearchInput from '../Dashboard/SearchBar/QuickSearchInput'
import Markdown from 'markdown-to-jsx'
import Logo from '../Dashboard/components/logo192.png'
import styles from './styles.module.scss'
import { ChatType } from '.'
import Skeleton from 'react-loading-skeleton'
import ChatInput from './ChatInput'

interface Props {
  chatHistory: ChatType[]
  onValueChange: (text: string) => void
  onSendMessage: (text: string) => void
  value: string
  loadingResponse: boolean
  loadingChatSession: boolean
  onSuggestedConversationClick: (text: string) => void
}

const DialogueArea = (props: Props) => {
  const { chatHistory, onSendMessage, onValueChange, value, loadingResponse, onSuggestedConversationClick, loadingChatSession } = props
  const latestMessageRef = useRef<null | HTMLDivElement>(null)
  const scrollToBottom = () => {
    latestMessageRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  useEffect(() => {
    scrollToBottom()
  }, [chatHistory.length])

  return (
    <div className={styles.chatContainer}>
      {loadingChatSession ? (
        <Skeleton width={'25%'} />
      ) : (
        <>
          {chatHistory.length === 1 ? (
            <QuickSearchInput
              onSuggestedConversationClick={onSuggestedConversationClick}
              className="noBg"
              heading={<p className={styles.inputHeading}>Ask anything about startups, news, or trends</p>}
              input={<ChatInput onSendMessage={onSendMessage} onValueChange={onValueChange} value={value} isNewConversation={true} />}
              hidePreviousQueriesButton={true}
            />
          ) : (
            <div>
              {chatHistory.map((chat, i) => {
                if (chat.sender === 'user')
                  return (
                    <div key={i} className={styles.flexEnd}>
                      <p className={styles.userMessage}>{chat.message}</p>
                    </div>
                  )
                else
                  return (
                    <div key={i} className={styles.responseMessage}>
                      {chat.message && (
                        <div className={styles.iconContainer}>
                          <img className={styles.chatIcon} src={Logo} />
                        </div>
                      )}
                      <Markdown>{chat.message}</Markdown>
                    </div>
                  )
              })}
              {loadingResponse && <Skeleton width={'25%'} />}
            </div>
          )}
        </>
      )}
      {chatHistory.length > 1 && <ChatInput onSendMessage={onSendMessage} onValueChange={onValueChange} value={value} isNewConversation={false} />}
      <div ref={latestMessageRef} />
    </div>
  )
}

export default DialogueArea
