import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import {
  autoSizeColumns,
  columnsInitiallyHidden,
  columnsShowingYear,
  getCurrentSortForAhoy,
  moneyColDef,
  ServerSideDatasource,
  standardColumns,
} from '../../../logic/AgGrid/AgGrid'
import { HtmlTooltip } from '../../components/HtmlTooltip'
import NameWithHomepageLink from '../../components/NameWithHomepageLink'
import {
  addressLink,
  CountryFormat,
  flagAndCountryName,
  fundingType,
  internationalUnits,
  linkedinUrlNormalization,
  listCount,
  listTooltip,
  liType,
  mailtoLinks,
  percentage,
  range,
  revenueParser,
  salesTargetParser,
  SnsLink,
  stateName,
  status,
  telLink,
} from '../../../logic/ValueFormatters'
import * as Backend from '../../../logic/Backend'
import { CompanyData, ListData } from '../../../logic/Backend'
import { Cache, Set } from '../Standard'
import * as Routes from '../../../logic/Routes'
import { hrefForId } from '../../../logic/Routes'
import ReactDOM from 'react-dom'
import { ColDef, ColGroupDef, GridApi, GridOptions, GridReadyEvent, IToolPanelComp, ToolPanelVisibleChangedEvent } from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { nameof, switchRating } from '../../../logic/Helpers'
import { LocalStorage } from '../../../logic/ClientSideStorage'
import { HotkeyConfig, useHotkeys } from '@blueprintjs/core'
import LayoutResetUi from '../../components/LayoutResetUi'
import { propertiesFromRating, UserRatingPickerCellRenderer } from '../../../stories/UserRatingPicker'
import { RATINGS } from '../../../resources/reference_data/ratings'
import * as AgGridPagination from '../../../logic/AgGrid/Pagination'
import { PaginationState } from '../../../logic/AgGrid/Pagination'
import * as AgGridSidebar from '../../../logic/AgGrid/Sidebar'
import ahoy from 'ahoy.js'
import { MlRatingLabelCellRenderer } from '../../../stories/RatingLabel'
import { Column } from '../../../logic/AgGrid/Column'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { useDispatch } from 'react-redux'
import PipelineStatusCellRenderer from '../Pipeline/PipelineStatusPicker/PipelineStatusCellRenderer'
import i18n from '../../../i18n'

export const Grid: FC<
  {
    cache?: Cache
    setCache: Set<Cache | undefined>
    agGridOptions?: GridOptions
    focusedListName?: ListData['name']
  } & IncludeT &
    WithNamespaces
> = (props) => {
  const [lists, setLists] = useState<ListData[]>([])
  const listIdsRef = useRef<ListData['id'][]>([])
  const listIdsNameMapRef = useRef<Record<ListData['id'], ListData['name']>>({})
  const [serverSideDatasource, setServerSideDatasource] = useState<ServerSideDatasource<CompanyData>>()
  const [gridSetupIsReady, setGridSetupIsReady] = useState(false)
  const [sidebarElement, setSidebarElement] = useState<Element | null>(null)
  const [statusBarElement, setStatusBarElement] = useState<Element | null>(null)
  const paginationState = useRef<PaginationState>({ currentPage: 1 })
  const [localStorage, setLocalStorage] = useState(new LocalStorage(ServerSideDatasource.listId ? `List/${ServerSideDatasource.listId}` : 'Companies/Grid'))
  const [selectedRows, setSelectedRows] = useState<CompanyData[]>([])
  const selectedNodes = props.cache?.api?.getSelectedNodes() || []
  const [isLoading, setIsLoading] = useState(false)
  const [languageIsJp, setLanguageIsJp] = useState(window.localStorage.getItem('language') === 'jp') //default to en
  const dispatch = useDispatch()
  const { t } = props

  i18n.on('languageChanged', (lng) => {
    setLanguageIsJp(lng === 'jp')
  })

  async function hotkeysHandler(event: KeyboardEvent) {
    const keyCode = +event.code.replace('Digit', '')
    const rating_ = keyCode - 1
    if (selectedRows.length) {
      await switchRating(rating_, selectedRows, selectedNodes)
    }
  }

  const metaKey = /mac/i.test(navigator.platform) ? 'cmd' : 'ctrl'
  const keys: HotkeyConfig[] = RATINGS.map((ratingLabel, i) => ({
    combo: `${metaKey}+${i + 1}`,
    global: true,
    label: ratingLabel,
    preventDefault: true,
    onKeyDown: (event) => hotkeysHandler(event),
  }))

  const hotkeys = useMemo(() => keys, [selectedRows, props.cache])

  const { handleKeyDown, handleKeyUp } = useHotkeys(hotkeys)

  useEffect(() => {
    initLists()
    return () => {
      props.setCache({ ...props.cache, ...({ api: undefined, columnApi: undefined } as Cache) })
    }
  }, [])

  async function initLists() {
    if (lists.length === 0) {
      try {
        setLists(await Backend.lists())
      } catch {}
    }
  }

  useEffect(() => {
    listIdsRef.current = []
    listIdsNameMapRef.current = {}
    lists.forEach((list) => {
      listIdsRef.current.push(list.id)
      listIdsNameMapRef.current[list.id] = list.name
    })
  }, [lists])
  useEffect(() => {
    if (gridSetupIsReady && props.cache?.investmentProfiles && props.cache?.api) {
      const currentSortModel = props.cache.api.getSortModel()

      if (currentSortModel.length === 0) {
        if (props.cache?.investmentProfiles?.some((p) => p.is_active && p.status === 'predicted')) {
          props.cache?.columnApi?.applyColumnState({
            state: [
              { colId: 'ml_rating', sort: 'asc' },
              { colId: 'rating', sort: 'asc' },
            ],
          })
        }
      }

      updateDataSource_(props.cache.api)
    }
  }, [props.cache?.investmentProfiles, props.cache?.api, gridSetupIsReady])
  function columns(): ColGroupDef[] {
    return (
      [
        {
          checkboxSelection: true,
          pinned: 'left',
          width: 40,
          cellClass: 'no-active-border',
        },
        {
          headerName: t('Company'),
          children: [
            {
              field: nameof<CompanyData>('name'),
              cellRenderer: 'NameWithHomepageLink',
              tooltipcomponent: 'HtmlTooltip',
              sortable: true,
              title: 'HtmlTooltip',
              pinned: 'left',
              hide: languageIsJp,
            },
            {
              field: nameof<CompanyData>('name_jp'),
              cellRenderer: 'NameWithHomepageLink',
              tooltipcomponent: 'HtmlTooltip',
              sortable: true,
              title: 'HtmlTooltip',
              pinned: 'left',
              hide: !languageIsJp,
              valueGetter: (params) => {
                return params.data.name_jp ? params.data.name_jp : params.data.name
              },
            },
          ],
        },
        {
          headerName: t('Review'),
          children: [
            {
              field: nameof<CompanyData>('ml_rating'),
              cellRenderer: 'MlRatingLabelCellRenderer',
              tooltipValueGetter: (params) => propertiesFromRating(params.value, true)?.label,
              tooltipcomponent: 'HtmlTooltip',
              title: 'HtmlTooltip',
              sortable: true,
              pinned: 'left',
            },
            {
              field: nameof<CompanyData>('rating'),
              cellRenderer: 'UserRatingPickerCellRenderer',
              tooltipValueGetter: (params) => propertiesFromRating(params.value)?.label,
              tooltipcomponent: 'HtmlTooltip',
              sortable: true,
              title: 'HtmlTooltip',
              pinned: 'left',
            },
            {
              field: nameof<CompanyData>('pipeline_cards'),
              headerName: t('Pipeline Status'),
              cellRenderer: 'PipelineStatusCellRenderer',
              sortable: false,
              pinned: 'left',
            },
            {
              field: nameof<CompanyData>('recommended_score'),
              headerName: t('Recommended Score'),
              sortable: true,
              pinned: 'left',
              filter: 'agNumberColumnFilter',
              hide: true,
            },
            {
              field: nameof<CompanyData>('created_at'),
              headerName: t('Created At'),
              sortable: true,
              pinned: 'left',
              hide: true,
            },
            ...[
              {
                field: nameof<CompanyData>('list_ids'),
                valueFormatter: listCount,
                tooltipcomponent: 'HtmlTooltip',
                title: 'HtmlTooltip',
                hide: true,
                tooltipValueGetter: (params) => listTooltip(params, listIdsNameMapRef.current),
                // cellRenderer: (params) => listTooltip(params, lists),
              } as ColDef,
            ],
          ],
        },
        {
          headerName: t('Basics'),
          children: [
            { field: nameof<CompanyData>('domain') },
            {
              field: nameof<CompanyData>('short_description'),
              hide: languageIsJp,
              valueGetter: (params) => {
                return params.data.short_description ? params.data.short_description : params.data.short_description_jp
              },
            },
            {
              field: nameof<CompanyData>('short_description_jp'),
              hide: !languageIsJp,

              valueGetter: (params) => {
                return params.data.short_description_jp ? params.data.short_description_jp : params.data.short_description
              },
            },
            {
              field: nameof<CompanyData>('description'),
              hide: languageIsJp,
              valueGetter: (params) => {
                return params.data.description ? params.data.description : params.data.description_jp
              },
            },
            {
              field: nameof<CompanyData>('description_jp'),
              hide: !languageIsJp,
              valueGetter: (params) => {
                return params.data.description_jp ? params.data.description_jp : params.data.description
              },
            },
            { field: nameof<CompanyData>('founded_on_date') },
            {
              field: nameof<CompanyData>('country_iso_3166_1_alpha_3'),
              headerName: t('Country'),
              valueFormatter: (params) => flagAndCountryName(params.value, CountryFormat.Code),
              tooltipValueGetter: (params) => flagAndCountryName(params.value, CountryFormat.Name),
              sortable: true,
            },
            {
              field: nameof<CompanyData>('state'),
              headerName: t('State'),
              valueFormatter: (params) => stateName(params.value),
              tooltipValueGetter: (params) => stateName(params.value),
            },
            {
              field: nameof<CompanyData>('city'),
              headerName: t('City'),
            },
            { field: nameof<CompanyData>('postal_code'), headerName: t('Postal Code') },
            {
              field: nameof<CompanyData>('status_enum'),
              headerName: t('Operating Status'),
              valueFormatter: status,
              sortable: true,
            },
            {
              field: nameof<CompanyData>('li_type_enum'),
              headerName: t('Company Type'),
              valueFormatter: (v) => liType(v.value),
              sortable: true,
            },
            {
              field: nameof<CompanyData>('employee_count_int4range'),
              headerName: t('Employee Range'),
              valueFormatter: range,
              sortable: true,
            },
          ],
        },
        {
          headerName: t('Kurrant.ai Insights'),
          children: [
            { field: nameof<CompanyData>('sales_target_type'), valueFormatter: (v) => salesTargetParser(v.value) },
            {
              field: nameof<CompanyData>('revenue_stream_type'),
              // v is the returned object, with v.value being the returned value from db (e.g. 'non_recurring')
              valueFormatter: (v) => revenueParser(v.value),
            },
            { field: nameof<CompanyData>('industry_groups'), headerName: t('Industry Group') },
            { field: nameof<CompanyData>('industries'), headerName: t('Industry') },
            { field: nameof<CompanyData>('sub_industries'), headerName: t('Sub Industries') },
            { field: nameof<CompanyData>('region') },
            { field: nameof<CompanyData>('sub_region') },
          ],
        },
        {
          headerName: t('Growth'),
          children: [
            {
              field: nameof<CompanyData>('li_employee_count_integer'),
              headerName: `LinkedIn ${t('Employees')}`,
              valueFormatter: (params) => internationalUnits(params.value, 1),
              sortable: true,
            },
            {
              field: nameof<CompanyData>('li_employee_count_mom_decimal'),
              headerName: `LinkedIn ${t('Employees Monthly Growth')}`,
              valueFormatter: (params) => percentage(params.value),
              sortable: true,
            },
            {
              field: nameof<CompanyData>('bw_revenue_usd_bigint'),
              headerName: t('Estimated Sales Revenue'),
              valueFormatter: (params) => internationalUnits(params.value, 1, '$'),
              sortable: true,
            },
            {
              field: nameof<CompanyData>('bw_revenue_mom_decimal'),
              headerName: t('Estimated Sales Revenue Monthly Growth'),
              valueFormatter: (params) => percentage(params.value),
              sortable: true,
            },
            {
              field: nameof<CompanyData>('tech_spent_bigint_usd'),
              headerName: t('Tech Spend'),
              valueFormatter: (params) => internationalUnits(params.value, 1, '$'),
              sortable: true,
            },
            {
              field: nameof<CompanyData>('ig_followers_count_integer'),
              headerName: `Instagram ${t('Followers')}`,
              valueFormatter: (params) => internationalUnits(params.value, 1),
              sortable: true,
            },
            {
              field: nameof<CompanyData>('ig_followers_count_mom_decimal'),
              headerName: `Instagram ${t('Followers Monthly Growth')}`,
              valueFormatter: (params) => percentage(params.value),
              sortable: true,
            },
            {
              field: nameof<CompanyData>('li_followers_count_integer'),
              headerName: `LinkedIn ${t('followers')}`,
              valueFormatter: (params) => internationalUnits(params.value, 1),
              sortable: true,
            },
            {
              field: nameof<CompanyData>('li_followers_count_mom_decimal'),
              headerName: `LinkedIn ${t('Followers Monthly Growth')}`,
              valueFormatter: (params) => percentage(params.value),
              sortable: true,
            },
          ],
        },
        {
          headerName: t('Financials'),
          children: [
            { field: nameof<CompanyData>('total_assets_bigint_usd'), headerName: t('Total Assets'), ...moneyColDef },
            { field: nameof<CompanyData>('revenue_bigint_usd'), headerName: t('Revenue'), ...moneyColDef },
            { field: nameof<CompanyData>('net_income_bigint_usd'), headerName: t('Net Income'), ...moneyColDef },
            { field: nameof<CompanyData>('operating_income_bigint_usd'), headerName: t('Operating Income'), ...moneyColDef },
          ],
        },
        {
          headerName: t('Funding'),
          children: [
            { field: nameof<CompanyData>('total_funding_usd_bigint'), headerName: t('Total Funding'), ...moneyColDef },
            { field: nameof<CompanyData>('investors'), headerName: t('Investors') },
            { field: nameof<CompanyData>('latest_funding_amount_bigint'), headerName: t('Latest Funding'), ...moneyColDef },
            { field: nameof<CompanyData>('latest_funding_on_date'), headerName: t('Latest Funding In') },
            {
              field: nameof<CompanyData>('latest_funding_type'),
              headerName: t('Latest Funding Type'),
              valueFormatter: fundingType,
            },
            { field: nameof<CompanyData>('funding_round_count_integer'), headerName: t('Funding Rounds'), sortable: true },
            { field: nameof<CompanyData>('exit_count_integer'), headerName: t('Exit Count'), sortable: true },
          ],
        },
        {
          headerName: t('Social Media'),
          children: [
            {
              field: nameof<CompanyData>('linkedin_url'),
              headerName: 'LinkedIn',
              valueGetter: (params) => linkedinUrlNormalization(params.data?.[nameof<CompanyData>('linkedin_url')]),
              cellRenderer: 'SnsLink',
              cellRendererParams: { sns: 'linkedin' },
            },
            {
              field: nameof<CompanyData>('facebook_url'),
              headerName: 'Facebook',
              cellRenderer: 'SnsLink',
              cellRendererParams: { sns: 'facebook' },
            },
            {
              field: nameof<CompanyData>('twitter_url'),
              headerName: 'Twitter',
              cellRenderer: 'SnsLink',
              cellRendererParams: { sns: 'twitter' },
            },
            {
              field: nameof<CompanyData>('ig_username'),
              headerName: 'Instagram',
              cellRenderer: 'SnsLink',
              cellRendererParams: { sns: 'instagram' },
            },
            {
              field: nameof<CompanyData>('pinterest_url'),
              headerName: 'Pinterest',
              cellRenderer: 'SnsLink',
              cellRendererParams: { sns: 'pinterest' },
            },
          ],
        },
        {
          headerName: t('Contact'),
          children: [
            { field: nameof<CompanyData>('address'), headerName: t('Address'), cellRenderer: addressLink },
            { field: nameof<CompanyData>('emails'), headerName: t('Email'), cellRenderer: (p) => mailtoLinks(p.value) },
            { field: nameof<CompanyData>('phones'), headerName: t('Phone'), cellRenderer: telLink },
          ],
        },
      ] as ColGroupDef[]
    ).map((colGroup: ColGroupDef, i) => {
      colGroup.headerClass = 'col-group-' + i
      if (colGroup.children) {
        colGroup.children.forEach((col: ColDef) => {
          const headerName = Column.withId(col.field as keyof CompanyData).label
          col.headerName = t(headerName)
          columnsShowingYear(col, [nameof<CompanyData>('founded_on_date'), nameof<CompanyData>('latest_funding_on_date')])
          columnsInitiallyHidden(col, [
            nameof<CompanyData>('domain'),
            nameof<CompanyData>('homepage'),
            nameof<CompanyData>('description'),
            nameof<CompanyData>('postal_code'),
            nameof<CompanyData>('net_income_bigint_usd'),
            nameof<CompanyData>('operating_income_bigint_usd'),
            nameof<CompanyData>('latest_funding_type'),
            nameof<CompanyData>('funding_round_count_integer'),
            nameof<CompanyData>('exit_count_integer'),
            nameof<CompanyData>('address'),
            nameof<CompanyData>('emails'),
            nameof<CompanyData>('phones'),
            nameof<CompanyData>('ig_followers_count_integer'),
          ])
          standardColumns(col, colGroup)
        })
      }
      return colGroup
    })
  }

  function updateDataSource_(api?: GridApi) {
    setServerSideDatasource(
      new ServerSideDatasource(api, Backend.companiesPage, (response, exactCountResources) => {
        AgGridPagination.setRowsCountAndLastPage(paginationState, response, api)
        AgGridPagination.render(paginationState, ['company', 'companies'], statusBarElement, props.cache?.api, isLoading)
        AgGridSidebar.render(sidebarElement, props.cache?.api)
        setIsLoading(true)
        Backend.companiesExactCount({ ...exactCountResources.params.request, listId: exactCountResources.listId, filterModel: exactCountResources.filterModel })
          .then((res) => {
            // update grid based on exact count
            exactCountResources.params.success({ rowData: response.rows, rowCount: res.rowsCount })
            paginationState.current.rowsCount = [res.rowsCount ?? 0, true]

            dispatch({ type: 'totalCompanyCountState/updateCompanyCountState', payload: res.rowsCount })
            setIsLoading(false)
            AgGridPagination.render(paginationState, ['company', 'companies'], statusBarElement, props.cache?.api, isLoading, false)
          })
          .catch((error) => {
            console.error('Failed to get exact company count:', error)
          })
        AgGridPagination.setRowsCountAndLastPage(paginationState, response, api)
      })
    )
  }

  async function onGridReady(event: GridReadyEvent) {
    props.setCache?.({ ...props.cache, ...({ api: event.api, columnApi: event.columnApi } as Cache) })
    localStorage.loadGridColumnState(event)
    AgGridPagination.prepareStatusBar(statusBarElement, setStatusBarElement)
    AgGridSidebar.prepareSidebar(sidebarElement, setSidebarElement)

    setGridSetupIsReady(true)
  }

  const onSortChanged: GridOptions['onSortChanged'] = (event) => {
    localStorage.saveGridColumnState(event)
    AgGridPagination.setToPage1(paginationState)
    AgGridPagination.render(paginationState, ['company', 'companies'], statusBarElement, props.cache?.api)
    AgGridSidebar.render(sidebarElement, props.cache?.api)
    const sort = getCurrentSortForAhoy(event)
    ahoy.track('changeSort', { sort })
  }

  const onFilterChanged: GridOptions['onFilterChanged'] = () => {
    AgGridPagination.setToPage1(paginationState)
    AgGridPagination.render(paginationState, ['company', 'companies'], statusBarElement, props.cache?.api)
    AgGridSidebar.render(sidebarElement, props.cache?.api)
    ahoy.track('changeFilters', { filters: ServerSideDatasource.compactFilterModel() })
  }

  function initializeLayoutResetUi(api: GridApi) {
    const parent = document.createElement('div')
    parent.style.cssText = 'position: sticky; top: 0; z-index: 1; text-align: center; padding: 6px 0;'
    const filtersToolPanel = api.getToolPanelInstance('columns') as unknown as IToolPanelComp
    filtersToolPanel.getGui().prepend(parent)
    ReactDOM.render(<LayoutResetUi />, parent)
  }

  const onFirstDataRendered: GridOptions['onFirstDataRendered'] = (event) => {
    autoSizeColumns(event, localStorage, [
      nameof<CompanyData>('ml_rating'),
      nameof<CompanyData>('rating'),
      nameof<CompanyData>('list_ids'),
      nameof<CompanyData>('founded_on_date'),
      nameof<CompanyData>('country_iso_3166_1_alpha_3'),
      nameof<CompanyData>('state'),
      nameof<CompanyData>('city'),
      nameof<CompanyData>('status_enum'),
      nameof<CompanyData>('employee_count_int4range'),
      nameof<CompanyData>('total_assets_bigint_usd'),
      nameof<CompanyData>('revenue_bigint_usd'),
      nameof<CompanyData>('net_income_bigint_usd'),
      nameof<CompanyData>('operating_income_bigint_usd'),
      nameof<CompanyData>('latest_funding_on_date'),
      nameof<CompanyData>('linkedin_url'),
      nameof<CompanyData>('facebook_url'),
      nameof<CompanyData>('twitter_url'),
    ])
  }

  function onToolPanelVisibleChanged(event: ToolPanelVisibleChangedEvent) {
    localStorage.saveGridOpenedToolPanel(event)
  }

  const onSelectionChanged: GridOptions['onSelectionChanged'] = (event) => {
    setSelectedRows(event.api.getSelectedRows())
  }
  useEffect(() => {
    dispatch({ type: 'selectedCompanyState/updateSelectedCompanyState', payload: selectedRows })
  }, [selectedRows])

  const onRowDoubleClicked: GridOptions['onRowDoubleClicked'] = (event) => {
    const company = event.data as CompanyData
    if (company) {
      props.setCache({ ...props.cache, ...({ doubleClickedCompany: company } as Cache) })
      let target = `${hrefForId(Routes.company, company.id)}`
      if (ServerSideDatasource.listId) {
        target = `${target}?focusedListId=${ServerSideDatasource.listId}&focusedListName=${props.focusedListName}`
      }
      window.open(target)
    }
  }

  const resizeListener = (e: any) => {
    dispatch({ type: 'companyNameColumnSizeState/updateColumnSizeState', payload: e || undefined })

    e.finished && localStorage.saveGridColumnState(e)
  }

  return (
    <div className="ag-theme-alpine" style={{ display: 'flex', flex: '1 1 auto', height: '100%' }} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
      <AgGridReact
        getRowClass={(params) => {
          if (params.data && params.data.rating == 3) {
            return 'passedCompany'
          }
        }}
        containerStyle={{ flexGrow: 'inherit' }}
        rowModelType={'serverSide'}
        serverSideStoreType={'partial'}
        rowSelection={'multiple'}
        suppressMultiSort={true}
        pagination={true}
        suppressPaginationPanel={true}
        // paginationPageSize={10}
        // cacheBlockSize={100}
        suppressColumnVirtualisation={true}
        sideBar={{
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
              },
            },
          ],
        }}
        defaultColDef={{
          resizable: true,
          filter: true,
          menuTabs: ['generalMenuTab'],
          sortingOrder: ['desc', 'asc', null],
        }}
        rowHeight={70}
        autoSizePadding={10}
        columnDefs={columns()}
        tooltipShowDelay={0}
        tooltipMouseTrack={true}
        onGridReady={onGridReady}
        onSortChanged={onSortChanged}
        onFilterChanged={onFilterChanged}
        onFirstDataRendered={onFirstDataRendered}
        onToolPanelVisibleChanged={onToolPanelVisibleChanged}
        onSelectionChanged={onSelectionChanged}
        onRowDoubleClicked={onRowDoubleClicked}
        onColumnVisible={(e) => localStorage.saveGridColumnState(e)}
        onColumnPinned={(e) => localStorage.saveGridColumnState(e)}
        onColumnResized={resizeListener}
        onColumnMoved={(e) => localStorage.saveGridColumnState(e)}
        onColumnGroupOpened={(e) => localStorage.saveGridColumnState(e)}
        suppressContextMenu={true}
        frameworkComponents={{
          HtmlTooltip: HtmlTooltip,
          NameWithHomepageLink: NameWithHomepageLink,
          UserRatingPickerCellRenderer: UserRatingPickerCellRenderer,
          MlRatingLabelCellRenderer: MlRatingLabelCellRenderer,
          SnsLink: SnsLink,
          PipelineStatusCellRenderer: PipelineStatusCellRenderer,
        }}
        {...props.agGridOptions}
      />
    </div>
  )
}

export default withNamespaces()(Grid)
