import React from 'react'
import { useHistory } from 'react-router-dom'
import styles from './styles.module.scss'

interface SeeMoreButtonProps {
  url?: string
  onClick?: () => void
  disabled?: boolean
}

const SeeMoreButton: React.FC<SeeMoreButtonProps> = ({ url, onClick, disabled }) => {
  const history = useHistory()

  const handleClick = () => {
    if (onClick) {
      onClick()
    } else if (url) {
      history.push(url)
    }
  }

  return (
    <button className={styles['see-more']} onClick={handleClick} disabled={disabled}>
      Discover More
    </button>
  )
}

export default SeeMoreButton
