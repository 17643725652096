import React, { FC, useState, useEffect } from 'react'
import ContainerComponent from '../components/ContainerComponent'
import styles from '../components/styles.module.scss'
import { getNewlyDiscoveredCompanies } from '../../../../logic/Backend'
import { DateTime } from 'luxon'
import CompanyItem from '../components/CompanyItem'
import SeeMoreButton from '../components/SeeMoreButton'
import { useFetchCompanies } from '../components/useFetchCompanies'
import Skeleton from 'react-loading-skeleton'
import { useHistory } from 'react-router-dom'
import { t } from 'i18next'
import useIsSmallScreen from '../../../../hooks/useDetectScreenSize'

export const NewlyDiscoveredCompanies: FC = () => {
  const { companies, visibleCompanies, setFrom, total_count, loading } = useFetchCompanies(0, 10, getNewlyDiscoveredCompanies)
  const [initialLoading, setInitialLoading] = useState(true)
  const [paginationLoading, setPaginationLoading] = useState(false)
  const history = useHistory()
  const isSmallScreen = useIsSmallScreen()
  useEffect(() => {
    if (!loading) {
      setInitialLoading(false)
      setPaginationLoading(false)
    }
  }, [loading])

  function formatRelativeDate(dateString: string): string {
    const date = DateTime.fromISO(dateString).startOf('day')
    const now = DateTime.now().startOf('day')
    const diff = now.diff(date, 'days').toObject()

    if (diff.days !== undefined) {
      if (diff.days < 1) return t('Today')
      if (diff.days < 2) return t('Yesterday')
      if (diff.days < 7) return `${Math.floor(diff.days)} ${t('Days Ago')}`
      if (diff.days <= 30) return `${Math.floor(diff.days)} ${t('Days Ago')}`
      return t('1 Month +')
    }
  }

  const uniqueVisibleCompanies = Array.from(new Set(visibleCompanies.map((company) => company.id))).map((id) =>
    visibleCompanies.find((company) => company.id === id)
  )

  const sortedVisibleCompanies = uniqueVisibleCompanies.sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime() || b.recommended_score - a.recommended_score
  )

  const handleScroll = (e: React.UIEvent<HTMLDivElement>): void => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
    if (scrollHeight - scrollTop <= clientHeight + 1 && companies.length < total_count && !paginationLoading) {
      if (companies.length < 25) {
        setPaginationLoading(true)
        setFrom((prevFrom) => prevFrom + 10)
      }
    }
  }

  const handleSeeMoreClick = () => {
    history.push({
      pathname: '/companies',
      state: {
        sortModel: [{ colId: 'created_at', sort: 'desc' }],
      },
    })
  }

  const renderedItems = (
    <div className={styles.scrollable} onScroll={handleScroll}>
      {initialLoading
        ? ((<Skeleton count={8} height={80} />) as React.ReactElement)
        : sortedVisibleCompanies.map((company) => (
            <CompanyItem
              key={company.id}
              company={company}
              additionalInfo={
                <div className={styles.newlyDiscoveredCompanies}>
                  {t('DISCOVERED')} {formatRelativeDate(company.created_at)}
                </div>
              }
            />
          ))}
      {paginationLoading && <Skeleton count={1} height={80} />}
      {companies.length >= 25 && <SeeMoreButton onClick={handleSeeMoreClick} />}
    </div>
  )

  return (
    <div className={styles.fullWidthContainer}>
      <h2 className={styles.header}>{t('Discovered Companies')}</h2>
      <ContainerComponent
        // title={!isSmallScreen && t('Discovered Companies')}
        seeMoreButton={<SeeMoreButton onClick={handleSeeMoreClick} />}
        renderedItems={renderedItems}
      />
    </div>
  )
}

export default NewlyDiscoveredCompanies
