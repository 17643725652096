import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import styles from './style.module.scss'
import NewsItem from './NewsItem'
import { getLatestNews } from '../../../../logic/Backend'
import { getNewsFilters } from '../../../../logic/Backend'
import FilterContainer from './FilterContainer'
import { INewsItem } from '../../../../logic/Backend'
import Skeleton from 'react-loading-skeleton'
import { t } from 'i18next'

type NewsResponse = {
  from: number
  to: number
  total_count: number
  news: INewsItem[]
}

const News = () => {
  const [news, setNews] = useState<NewsResponse>({ from: 0, to: 0, total_count: 0, news: [] })
  const [filters, setFilters] = useState({})
  const [activeFilters, setActiveFilters] = useState({})
  const [loading, setLoading] = useState(false)
  const [shouldClearFilter, setShouldClearFilter] = useState(false)
  const localization = localStorage.getItem('language')
  const [paginationLoading, setPaginationLoading] = useState(false)
  const [from, setFrom] = useState(0)

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getNewsFilters()
        setFilters(res)
        return res
      } catch (e) {
        console.log('Error fetching news filters: ', e)
      }
    }
    getData()
  }, [])

  useEffect(() => {
    const getNews = async () => {
      try {
        from === 0 && setLoading(true)
        const res = await getLatestNews({ filters: activeFilters, from: from })
        from === 0
          ? setNews(res)
          : setNews((prevState) => {
              return { from: res.from, to: res.to, total_count: res.total_count, news: [...prevState.news, ...res.news] }
            })
      } catch (e) {
        console.error('Error fetching latest news:', e)
      } finally {
        setLoading(false)
        setPaginationLoading(false)
      }
    }
    getNews()
  }, [activeFilters, from])

  const onFilterSelected = (key: string, value: string) => {
    setActiveFilters((prevState) => {
      const newVal = {}
      newVal[key] = value
      return { ...prevState, ...newVal }
    })
    setNews((prevNews) => {
      return { ...prevNews, news: [] }
    })
    setFrom(0)
  }
  const handleClear = () => {
    setActiveFilters([])
    setShouldClearFilter(true)
    setFrom(0)
  }

  const resetClearFilter = () => {
    setShouldClearFilter(false)
  }
  const handleScroll = (e: React.UIEvent<HTMLDivElement>): void => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
    if (scrollHeight - scrollTop <= clientHeight + 1 && news.total_count + 1 > news.to && !paginationLoading) {
      setPaginationLoading(true)
      setTimeout(() => {
        setFrom((prevFrom) => prevFrom + 50)
      }, 1000) // Adding a 1000ms buffer to prevent double calls
    }
  }

  return (
    <div className={styles.newsContainer}>
      <h2 className={styles.heading}>{t('Latest News')}</h2>
      <FilterContainer
        filterValues={filters}
        onFilterSelected={onFilterSelected}
        handleClear={handleClear}
        shouldClearFilter={shouldClearFilter}
        resetClearFilter={resetClearFilter}
      />
      <div className={`${styles.newsGrid}`} onScroll={handleScroll}>
        {loading
          ? ((<Skeleton count={5} height={80} />) as ReactElement)
          : news?.news.map((news, i) => {
              return (
                <NewsItem
                  key={i}
                  companyName={news.company_name}
                  articleDate={news.published_date}
                  articleTitle={localization === 'jp' ? news.title_jp : news.title_en}
                  articleSummary={localization === 'jp' ? news.summary_jp : news.summary_en}
                  // tags={news.tags}
                  srcImg={news.image_url}
                  companyId={news.company_id}
                  logo={news.company_logo_id}
                  url={news.url}
                />
              )
            })}
        {paginationLoading && ((<Skeleton count={1} width={'200%'} height={80} />) as ReactElement)}
      </div>
    </div>
  )
}

export default News
