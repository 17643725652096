export const filterIndustryValueTranslations = {
  industry: {
    'Administrative Services': '行政サービス',
    Advertising: '広告',
    'Agriculture and Farming': '農業',
    Apps: 'アプリ',
    'Artificial Intelligence': '人工知能',
    Biotechnology: 'バイオテクノロジー',
    'Business Services': 'ビジネスサービス',
    'Clothing and Apparel': '衣料品',
    'Commerce and Shopping': '商業とショッピング',
    'Community and Lifestyle': 'コミュニティとライフスタイル',

    'Consumer Goods': '消費財',
    'Consumer Services': '消費者サービス',
    'Content and Publishing': 'コンテンツと出版',
    'Data and Analytics': 'データと分析',
    Design: 'デザイン',
    Education: '教育',
    Energy: 'エネルギー',
    Entertainment: 'エンターテインメント',
    Events: 'イベント',
    'Financial Services': '金融サービス',
    'Food and Beverage': '食品と飲料',
    Gaming: 'ゲーム',
    'Government and Military': '政府と軍事',
    'Government and Nonprofit': '政府と非営利団体',
    Hardware: 'ハードウェア',
    'Health Care': '医療',
    Healthcare: '医療',
    Industrial: '産業',
    'Information Technology': '情報技術',
    'Internet Services': 'インターネットサービス',
    'Lending and Investments': '貸付と投資',
    Manufacturing: '製造',
    Media: 'メディア',
    'Media and Entertainment': 'メディアとエンターテインメント',
    'Messaging and Telecommunications': 'メッセージングと通信',
    Mobile: 'モバイル',
    'Music and Audio': '音楽とオーディオ',
    'Natural Resources': '天然資源',
    'Navigation and Mapping': 'ナビゲーションと地図',
    Nonprofit: '非営利',
    Other: 'その他',
    Payments: '支払い',
    Platforms: 'プラットフォーム',
    'Privacy and Security': 'プライバシーとセキュリティ',
    'Professional Services': '専門サービス',
    'Real Estate': '不動産',
    'Sales and Marketing': '営業とマーケティング',
    'Science and Engineering': '科学と工学',
    Software: 'ソフトウェア',
    Sports: 'スポーツ',
    Sustainability: '持続可能性',
    Technology: '技術',
    Transportation: '輸送',
    'Travel and Tourism': '旅行と観光',
    Video: 'ビデオ',
    '3D Printing': '3Dプリンティング',
    '3D Technology': '3D技術',
    'A/B Testing': 'A/Bテスト',
    Accounting: '会計',
    'Ad Exchange': '広告取引所',
    'Ad Network': '広告ネットワーク',
    'Ad Retargeting': '広告リターゲティング',
    'Ad Server': '広告サーバー',
    'Ad Targeting': '広告ターゲティング',
    Adult: 'アダルト',
    'Advanced Materials': '先進材料',
    'Adventure Travel': '冒険旅行',
    'Advertising Platforms': '広告プラットフォーム',
    Advice: 'アドバイス',
    Aerospace: '航空宇宙',
    'Affiliate Marketing': 'アフィリエイトマーケティング',
    AgTech: 'アグテック',
    Agriculture: '農業',
    'Air Transportation': '航空輸送',
    'Alternative Medicine': '代替医療',
    Alumni: '同窓生',
    'American Football': 'アメリカンフットボール',
    'Amusement Park and Arcade': '遊園地とアーケード',
    Analytics: '分析',
    Android: 'アンドロイド',
    'Angel Investment': 'エンジェル投資',
    'Animal Feed': '飼料',
    Animation: 'アニメーション',
    'App Discovery': 'アプリ発見',
    'App Marketing': 'アプリマーケティング',
    'Application Performance Management': 'アプリケーション性能管理',
    'Application Specific Integrated Circuit (ASIC)': '特定用途向け集積回路',
    Aquaculture: '水産養殖',
    Architecture: '建築',
    'Archiving Service': 'アーカイブサービス',
    Art: 'アート',
    'Asset Management': '資産管理',
    'Assisted Living': '介護付き住宅',
    'Assistive Technology': '支援技術',
    Association: '協会',
    Auctions: 'オークション',
    Audio: 'オーディオ',
    Audiobooks: 'オーディオブック',
    'Augmented Reality': '拡張現実',
    'Auto Insurance': '自動車保険',
    Automotive: '自動車',
    'Autonomous Vehicles': '自動運転車',
    Baby: '赤ちゃん',
    Bakery: 'パン屋',
    Banking: '銀行業',
    Baseball: '野球',
    Basketball: 'バスケットボール',
    Battery: 'バッテリー',
    Beauty: '美容',
    'Big Data': 'ビッグデータ',
    Billing: '請求',
    Biofuel: 'バイオ燃料',
    Bioinformatics: 'バイオインフォマティクス',
    'Biomass Energy': 'バイオマスエネルギー',
    Biometrics: '生体認証',
    Biopharma: 'バイオ製薬',
    Bitcoin: 'ビットコイン',
    'Blogging Platforms': 'ブログプラットフォーム',
    Boating: 'ボート',
    'Brand Marketing': 'ブランドマーケティング',
    Brewing: '醸造',
    Broadcasting: '放送',
    'Browser Extensions': 'ブラウザ拡張機能',
    'Building Maintenance': '建物のメンテナンス',
    'Building Material': '建築材料',
    'Business Development': '事業開発',
    'Business Information Systems': 'ビジネス情報システム',
    'Business Intelligence': 'ビジネスインテリジェンス',
    'Business Travel': '出張',
    CAD: 'CAD',
    CMS: 'CMS',
    CRM: 'CRM',
    'Call Center': 'コールセンター',
    Cannabis: '大麻',
    'Car Sharing': 'カーシェアリング',
    'Career Planning': 'キャリアプランニング',
    Casino: 'カジノ',
    'Casual Games': 'カジュアルゲーム',
    Catering: 'ケータリング',
    'Cause Marketing': '社会貢献マーケティング',
    Celebrity: '有名人',
    'Charter Schools': 'チャータースクール',
    Chemical: '化学',
    'Chemical Engineering': '化学工学',
    'Child Care': '保育',
    Children: '子供',
    CivicTech: 'シビックテック',
    'Civil Engineering': '土木工学',
    Classifieds: 'クラシファイド',
    'Clean Energy': 'クリーンエネルギー',
    CleanTech: 'クリーンテック',
    'Clinical Trials': '臨床試験',
    'Cloud Computing': 'クラウドコンピューティング',
    'Cloud Data Services': 'クラウドデータサービス',
    'Cloud Infrastructure': 'クラウドインフラストラクチャ',
    'Cloud Management': 'クラウド管理',
    'Cloud Security': 'クラウドセキュリティ',
    'Cloud Storage': 'クラウドストレージ',
    Coffee: 'コーヒー',
    Collectibles: 'コレクティブル',
    'Collection Agency': '取立て機関',
    'College Recruiting': '大学リクルーティング',
    Comics: 'コミック',
    'Commercial Insurance': '商業保険',
    'Commercial Lending': '商業融資',
    'Commercial Real Estate': '商業不動産',
    'Communication Hardware': '通信ハードウェア',
    'Communications Infrastructure': '通信インフラ',
    Communities: 'コミュニティ',
    Compliance: 'コンプライアンス',
    Computer: 'コンピュータ',
    'Computer Vision': 'コンピュータビジョン',
    Concerts: 'コンサート',
    Confectionery: '菓子',
    'Console Games': 'コンソールゲーム',
    Construction: '建設',
    Consulting: 'コンサルティング',
    'Consumer Applications': '消費者向けアプリケーション',
    'Consumer Electronics': '消費者向け電子機器',

    'Consumer Lending': '消費者向け融資',
    'Consumer Research': '消費者調査',
    'Consumer Reviews': '消費者レビュー',
    'Consumer Software': '消費者向けソフトウェア',
    'Contact Management': 'コンタクト管理',
    Content: 'コンテンツ',
    'Content Creators': 'コンテンツクリエイター',
    'Content Delivery Network': 'コンテンツデリバリーネットワーク',
    'Content Discovery': 'コンテンツ発見',
    'Content Marketing': 'コンテンツマーケティング',
    'Content Syndication': 'コンテンツ配信',
    Contests: 'コンテスト',
    'Continuing Education': '継続教育',
    Cooking: '料理',
    'Corporate Training': '企業研修',
    'Corrections Facilities': '矯正施設',
    'Cosmetic Surgery': '美容整形',
    Cosmetics: '化粧品',
    Coupons: 'クーポン',
    'Courier Service': '宅配便サービス',
    Coworking: 'コワーキング',
    'Craft Beer': 'クラフトビール',
    'Creative Agency': 'クリエイティブエージェンシー',
    Credit: '信用',
    'Credit Bureau': '信用情報機関',
    'Credit Cards': 'クレジットカード',
    Cricket: 'クリケット',
    Crowdfunding: 'クラウドファンディング',
    Cryptocurrency: '暗号通貨',
    'Customer Service': '顧客サービス',
    'Cyber Security': 'サイバーセキュリティ',
    Cycling: 'サイクリング',
    DIY: 'DIY',
    DRM: 'DRM',
    DSP: 'DSP',
    Darknet: 'ダークネット',
    'Data Center': 'データセンター',
    'Data Center Automation': 'データセンター自動化',
    'Data Integration': 'データ統合',
    'Data Mining': 'データマイニング',
    'Data Storage': 'データストレージ',
    'Data Visualization': 'データ可視化',
    Database: 'データベース',
    Dating: 'デート',
    'Debit Cards': 'デビットカード',
    'Debt Collections': '債権回収',
    Delivery: '配送',
    'Delivery Service': '配送サービス',
    Dental: '歯科',
    'Desktop Apps': 'デスクトップアプリ',
    'Developer APIs': '開発者向けAPI',
    'Developer Platform': '開発者プラットフォーム',
    'Developer Tools': '開発者ツール',
    Diabetes: '糖尿病',
    'Dietary Supplements': '栄養補助食品',
    'Digital Entertainment': 'デジタルエンターテインメント',
    'Digital Marketing': 'デジタルマーケティング',
    'Digital Media': 'デジタルメディア',
    'Digital Signage': 'デジタルサイネージ',
    'Direct Marketing': 'ダイレクトマーケティング',
    'Direct Sales': '直接販売',
    Distillery: '蒸留所',
    Diving: 'ダイビング',
    'Document Management': '文書管理',
    'Document Preparation': '文書作成',
    'Domain Registrar': 'ドメイン登録',
    'Drone Management': 'ドローン管理',
    Drones: 'ドローン',
    'E-Commerce': '電子商取引',
    'E-Commerce Platforms': '電子商取引プラットフォーム',
    'E-Learning': 'eラーニング',
    'E-Signature': '電子署名',
    EBooks: '電子書籍',
    EdTech: '教育技術',
    Ediscovery: '電子証拠開示',
    Edutainment: '教育エンターテインメント',
    'Elder Care': '高齢者ケア',
    Elderly: '高齢者',
    'Electric Vehicle': '電気自動車',
    'Electrical Distribution': '電気配電',
    'Electronic Design Automation (EDA)': '電子設計自動化',
    'Electronic Health Record (EHR)': '電子健康記録',
    Electronics: '電子機器',
    Email: 'メール',
    'Email Marketing': 'メールマーケティング',
    'Embedded Software': '組み込みソフトウェア',
    'Embedded Systems': '組み込みシステム',
    'Emergency Medicine': '救急医療',
    'Employee Benefits': '従業員福利厚生',
    Employment: '雇用',
    'Energy Efficiency': 'エネルギー効率',
    'Energy Management': 'エネルギー管理',
    'Energy Storage': 'エネルギー貯蔵',
    'Enterprise Applications': '企業向けアプリケーション',
    'Enterprise Resource Planning (ERP)': '企業資源計画',
    'Enterprise Software': '企業向けソフトウェア',
    'Environmental Consulting': '環境コンサルティング',
    'Environmental Engineering': '環境工学',
    Equestrian: '馬術',
    Ethereum: 'イーサリアム',
    'Event Management': 'イベント管理',
    'Event Promotion': 'イベントプロモーション',
    'Extermination Service': '駆除サービス',
    Eyewear: '眼鏡',
    Facebook: 'フェイスブック',
    'Facial Recognition': '顔認識',
    'Facilities Support Services': '施設サポートサービス',
    'Facility Management': '施設管理',
    Family: '家族',
    'Fantasy Sports': 'ファンタジースポーツ',
    'Farmers Market': 'ファーマーズマーケット',
    Farming: '農業',
    Fashion: 'ファッション',
    'Fast-Moving Consumer Goods': '日用消費財',
    'Ferry Service': 'フェリーサービス',
    Fertility: '生殖',
    'Field Support': 'フィールドサポート',
    'Field-Programmable Gate Array (FPGA)': 'フィールドプログラマブルゲートアレイ',
    'File Sharing': 'ファイル共有',
    Film: '映画',
    'Film Distribution': '映画配給',
    'Film Production': '映画制作',
    FinTech: 'フィンテック',
    Finance: '金融',
    'Financial Exchanges': '金融取引所',
    'First Aid': '応急処置',
    Fitness: 'フィットネス',
    'Flash Sale': 'フラッシュセール',
    'Flash Storage': 'フラッシュストレージ',
    'Fleet Management': '車両管理',
    Flowers: '花',
    'Food Delivery': '食事配達',
    'Food Processing': '食品加工',
    'Food Trucks': 'フードトラック',
    Forestry: '林業',
    'Fossil Fuels': '化石燃料',
    Foundries: '鋳造所',
    'Fraud Detection': '不正検出',
    Freelance: 'フリーランス',
    'Freight Service': '貨物サービス',
    Fruit: '果物',
    Fuel: '燃料',
    'Fuel Cell': '燃料電池',
    'Funding Platform': '資金調達プラットフォーム',
    Funerals: '葬儀',
    Furniture: '家具',
    GPS: 'GPS',
    GPU: 'GPU',
    Gambling: 'ギャンブル',
    Gamification: 'ゲーミフィケーション',
    Genetics: '遺伝学',
    Geospatial: '地理空間',
    Gift: 'ギフト',
    'Gift Card': 'ギフトカード',
    'Gift Exchange': 'ギフト交換',
    'Gift Registry': 'ギフト登録',
    Golf: 'ゴルフ',
    Google: 'グーグル',
    'Google Glass': 'グーグルグラス',
    GovTech: 'ガブテック',
    Government: '政府',
    'Graphic Design': 'グラフィックデザイン',
    'Green Building': 'グリーンビルディング',
    'Green Consumer Goods': 'グリーン消費財',
    GreenTech: 'グリーンテック',
    Grocery: '食料品',
    'Group Buying': '共同購入',
    Guides: 'ガイド',
    Handmade: 'ハンドメイド',
    'Health Diagnostics': '健康診断',
    'Health Insurance': '健康保険',
    'Hedge Funds': 'ヘッジファンド',
    'Higher Education': '高等教育',
    Hockey: 'ホッケー',
    'Home Decor': 'ホームデコレーション',
    'Home Health Care': '在宅医療',
    'Home Improvement': '住宅改善',
    'Home Renovation': '住宅改装',
    'Home Services': '家庭サービス',
    'Home and Garden': '家庭と庭',
    'Homeland Security': '国土安全',
    'Homeless Shelter': 'ホームレスシェルター',
    Horticulture: '園芸',
    Hospital: '病院',
    Hospitality: 'ホスピタリティ',
    Hotel: 'ホテル',
    'Housekeeping Service': 'ハウスキーピングサービス',
    'Human Computer Interaction': '人間コンピュータ相互作用',
    'Human Resources': '人事',
    Humanitarian: '人道的',
    Hunting: '狩猟',
    Hydroponics: '水耕栽培',
    ISP: 'ISP',
    'IT Infrastructure': 'ITインフラ',
    'IT Management': 'IT管理',
    IaaS: 'IaaS',
    'Identity Management': 'アイデンティティ管理',
    'Image Recognition': '画像認識',
    'Impact Investing': 'インパクト投資',
    'In-Flight Entertainment': '機内エンターテインメント',
    Incubators: 'インキュベーター',
    'Independent Music': 'インディペンデント音楽',
    'Indoor Positioning': '屋内測位',
    'Industrial Automation': '産業オートメーション',
    'Industrial Design': '工業デザイン',
    'Industrial Engineering': '産業工学',
    'Industrial Manufacturing': '産業製造',
    'Information Services': '情報サービス',
    'Information and Communications Technology (ICT)': '情報通信技術',
    Infrastructure: 'インフラストラクチャ',
    'Innovation Management': 'イノベーション管理',
    InsurTech: 'インシュアテック',
    Insurance: '保険',
    'Intellectual Property': '知的財産',
    'Intelligent Systems': 'インテリジェントシステム',
    'Interior Design': 'インテリアデザイン',
    Internet: 'インターネット',
    'Internet Radio': 'インターネットラジオ',
    'Internet of Things': 'モノのインターネット',
    'Intrusion Detection': '侵入検知',
    'Janitorial Service': '清掃サービス',
    Jewelry: 'ジュエリー',
    Journalism: 'ジャーナリズム',
    'Knowledge Management': '知識管理',
    LGBT: 'LGBT',
    Landscaping: '造園',
    'Language Learning': '語学学習',
    Laser: 'レーザー',
    'Last Mile Transportation': 'ラストマイル輸送',
    'Laundry and Dry-cleaning': '洗濯とドライクリーニング',
    'Law Enforcement': '法執行',
    'Lead Generation': 'リード生成',
    'Lead Management': 'リード管理',
    Leasing: 'リース',
    Legal: '法律',
    'Legal Tech': 'リーガルテック',
    Leisure: 'レジャー',
    Lending: '貸付',
    'Life Insurance': '生命保険',
    'Life Science': '生命科学',
    Lifestyle: 'ライフスタイル',
    Lighting: '照明',
    'Limousine Service': 'リムジンサービス',
    Lingerie: 'ランジェリー',
    Linux: 'リナックス',
    Livestock: '家畜',
    Local: 'ローカル',
    'Local Advertising': '地域広告',
    'Local Business': '地元ビジネス',
    'Local Shopping': '地元のショッピング',
    'Location Based Services': '位置情報サービス',
    Logistics: '物流',
    'Loyalty Programs': 'ロイヤルティプログラム',
    'MMO Games': 'MMOゲーム',
    MOOC: 'MOOC',
    'Machine Learning': '機械学習',
    'Machinery Manufacturing': '機械製造',
    'Made to Order': 'オーダーメイド',
    'Management Consulting': '経営コンサルティング',
    'Management Information Systems': '経営情報システム',
    'Mapping Services': '地図サービス',
    'Marine Technology': '海洋技術',
    'Marine Transportation': '海上輸送',
    'Market Research': '市場調査',
    Marketing: 'マーケティング',
    'Marketing Automation': 'マーケティングオートメーション',
    Marketplace: 'マーケットプレイス',
    'Mechanical Design': '機械設計',
    'Mechanical Engineering': '機械工学',
    Medical: '医療',
    'Medical Device': '医療機器',
    'Meeting Software': '会議ソフトウェア',
    "Men's": 'メンズ',
    Messaging: 'メッセージング',
    'Micro Lending': '小口融資',
    Military: '軍事',
    Mineral: '鉱物',
    Mining: '採掘',
    'Mining Technology': '採鉱技術',
    'Mobile Advertising': 'モバイル広告',
    'Mobile Apps': 'モバイルアプリ',
    'Mobile Devices': 'モバイルデバイス',
    'Mobile Payments': 'モバイル決済',
    'Motion Capture': 'モーションキャプチャ',
    'Multi-level Marketing': 'マルチ商法',
    'Museums and Historical Sites': '博物館と史跡',
    Music: '音楽',
    'Music Education': '音楽教育',
    'Music Label': '音楽レーベル',
    'Music Streaming': '音楽ストリーミング',
    'Music Venues': '音楽会場',
    'Musical Instruments': '楽器',
    NFC: 'NFC',
    Nanotechnology: 'ナノテクノロジー',
    'National Security': '国家安全保障',
    'Natural Language Processing': '自然言語処理',
    Navigation: 'ナビゲーション',
    'Network Hardware': 'ネットワークハードウェア',
    'Network Security': 'ネットワークセキュリティ',
    Neuroscience: '神経科学',
    News: 'ニュース',
    Nightclubs: 'ナイトクラブ',
    Nightlife: 'ナイトライフ',
    Nintendo: '任天堂',
    Nuclear: '核',
    'Nursing and Residential Care': '看護と介護',
    Nutraceutical: '栄養補助食品',
    Nutrition: '栄養',
    'Office Administration': 'オフィス管理',
    'Oil and Gas': '石油とガス',
    'Online Auctions': 'オンラインオークション',
    'Online Forums': 'オンラインフォーラム',
    'Online Games': 'オンラインゲーム',
    'Online Portals': 'オンラインポータル',
    'Open Source': 'オープンソース',
    'Operating Systems': 'オペレーティングシステム',
    'Optical Communication': '光通信',
    Organic: 'オーガニック',
    'Organic Food': 'オーガニック食品',
    'Outdoor Advertising': '屋外広告',
    Outdoors: 'アウトドア',
    'Outpatient Care': '外来ケア',
    Outsourcing: 'アウトソーシング',
    'PC Games': 'PCゲーム',
    PaaS: 'PaaS',
    'Packaging Services': '包装サービス',
    'Paper Manufacturing': '製紙業',
    Parenting: '子育て',
    Parking: '駐車',
    Parks: '公園',
    'Penetration Testing': '侵入テスト',
    'Performing Arts': '舞台芸術',
    'Personal Branding': '個人ブランディング',
    'Personal Development': '自己啓発',
    'Personal Finance': '個人金融',
    'Personal Health': '個人の健康',
    Personalization: '個別化',
    Pet: 'ペット',
    Pharmaceutical: '製薬',
    'Photo Editing': '写真編集',
    'Photo Sharing': '写真共有',
    Photography: '写真撮影',
    'Physical Security': '物理的セキュリティ',
    'Plastics and Rubber Manufacturing': 'プラスチックとゴム製造',
    Playstation: 'プレイステーション',
    Podcast: 'ポッドキャスト',
    'Point of Sale': '販売時点情報管理',
    Politics: '政治',
    'Pollution Control': '汚染管理',
    'Ports and Harbors': '港湾',
    'Power Grid': '電力網',
    'Precious Metals': '貴金属',
    'Prediction Markets': '予測市場',
    'Predictive Analytics': '予測分析',
    'Presentation Software': 'プレゼンテーションソフトウェア',
    Presentations: 'プレゼンテーション',
    'Price Comparison': '価格比較',
    'Primary Education': '初等教育',
    Printing: '印刷',
    Privacy: 'プライバシー',
    'Private Cloud': 'プライベートクラウド',
    'Private Social Networking': 'プライベートソーシャルネットワーキング',
    Procurement: '調達',
    'Product Design': '製品設計',
    'Product Research': '製品調査',
    'Product Search': '製品検索',
    'Productivity Tools': '生産性向上ツール',
    'Professional Networking': 'プロフェッショナルネットワーキング',
    'Project Management': 'プロジェクト管理',
    'Property Development': '不動産開発',
    'Property Insurance': '不動産保険',
    'Property Management': '不動産管理',
    Psychology: '心理学',
    'Public Relations': '広報',
    'Public Safety': '公共安全',
    'Public Transportation': '公共交通機関',
    Publishing: '出版',
    'Q&A': '質問と回答',
    'QR Codes': 'QRコード',
    'Quality Assurance': '品質保証',
    'Quantified Self': '定量的自己',
    'Quantum Computing': '量子コンピューティング',
    RFID: 'RFID',
    RISC: 'RISC',
    Racing: 'レース',
    Railroad: '鉄道',
    'Reading Apps': '読書アプリ',
    'Real Estate Investment': '不動産投資',
    Recipes: 'レシピ',
    Recreation: 'レクリエーション',
    'Recreational Vehicles': 'レクリエーション車',
    Recruiting: '採用',
    Recycling: 'リサイクル',
    Rehabilitation: 'リハビリテーション',
    Religion: '宗教',
    'Renewable Energy': '再生可能エネルギー',
    Rental: 'レンタル',
    'Rental Property': '賃貸物件',
    Reputation: '評判',
    Reservations: '予約',
    Residential: '住宅',
    Resorts: 'リゾート',
    Restaurants: 'レストラン',
    Retail: '小売',
    'Retail Technology': '小売技術',
    Retirement: '退職',
    'Ride Sharing': 'ライドシェアリング',
    'Risk Management': 'リスク管理',
    Robotics: 'ロボティクス',
    Roku: 'ロク',
    Rugby: 'ラグビー',
    SEM: 'SEM',
    SEO: 'SEO',
    SMS: 'SMS',
    SNS: 'SNS',
    'STEM Education': 'STEM教育',
    SaaS: 'SaaS',
    Sailing: 'セーリング',
    Sales: '営業',
    'Sales Automation': '営業自動化',
    'Same Day Delivery': '即日配達',
    'Satellite Communication': '衛星通信',
    Scheduling: 'スケジューリング',
    Seafood: 'シーフード',
    'Search Engine': '検索エンジン',
    'Secondary Education': '中等教育',
    Security: 'セキュリティ',
    'Self-Storage': 'セルフストレージ',
    'Semantic Search': 'セマンティック検索',
    'Semantic Web': 'セマンティックウェブ',
    Semiconductor: '半導体',
    Sensor: 'センサー',
    'Serious Games': 'シリアスゲーム',
    'Sex Industry': '性産業',
    'Sex Tech': '性技術',
    Shipping: '配送',
    'Shipping Broker': '配送ブローカー',
    Shoes: '靴',
    Shopping: 'ショッピング',
    'Shopping Mall': 'ショッピングモール',
    Simulation: 'シミュレーション',
    Skiing: 'スキー',
    'Skill Assessment': 'スキル評価',
    'Smart Building': 'スマートビルディング',
    'Smart Cities': 'スマートシティ',
    'Smart Home': 'スマートホーム',
    'Snack Food': 'スナック食品',
    Soccer: 'サッカー',
    Social: 'ソーシャル',
    'Social Assistance': '社会福祉',
    'Social Bookmarking': 'ソーシャルブックマーク',
    'Social CRM': 'ソーシャルCRM',
    'Social Entrepreneurship': '社会起業家',
    'Social Media': 'ソーシャルメディア',
    'Social Media Advertising': 'ソーシャルメディア広告',
    'Social Media Management': 'ソーシャルメディア管理',
    'Social Media Marketing': 'ソーシャルメディアマーケティング',
    'Social Network': 'ソーシャルネットワーク',
    'Social News': 'ソーシャルニュース',
    'Social Recruiting': 'ソーシャルリクルーティング',
    'Social Shopping': 'ソーシャルショッピング',
    'Software Engineering': 'ソフトウェア工学',
    Solar: 'ソーラー',
    'Space Travel': '宇宙旅行',
    'Spam Filtering': 'スパムフィルタリング',
    'Speech Recognition': '音声認識',
    Sponsorship: 'スポンサーシップ',
    'Sporting Goods': 'スポーツ用品',
    'Staffing Agency': '人材派遣会社',
    'Stock Exchanges': '証券取引所',
    'Supply Chain Management': 'サプライチェーン管理',
    Surfing: 'サーフィン',
    Swimming: '水泳',
    TV: 'テレビ',
    'TV Production': 'テレビ制作',
    'Table Tennis': '卓球',
    'Task Management': 'タスク管理',
    'Taxi Service': 'タクシーサービス',
    Tea: 'お茶',
    'Technical Support': '技術サポート',
    Teenagers: 'ティーンエイジャー',
    Telecommunications: '通信',
    Tennis: 'テニス',
    'Test and Measurement': 'テストと測定',
    'Text Analytics': 'テキスト分析',
    Textbook: '教科書',
    Textiles: '繊維',
    Theatre: '劇場',
    Therapeutics: '治療法',
    Ticketing: 'チケット販売',
    Timber: '木材',
    Timeshare: 'タイムシェア',
    Tizen: 'タイゼン',
    Tobacco: 'タバコ',
    'Tour Operator': 'ツアーオペレーター',
    Tourism: '観光',
    Toys: 'おもちゃ',
    'Trade Shows': '展示会',
    'Trading Platform': '取引プラットフォーム',
    Training: 'トレーニング',
    'Transaction Processing': '取引処理',
    'Translation Service': '翻訳サービス',
    Travel: '旅行',
    'Travel Accommodations': '旅行宿泊施設',
    'Travel Agency': '旅行代理店',
    Tutoring: '家庭教師',
    Twitter: 'ツイッター',
    'UX Design': 'UXデザイン',
    'Ultimate Frisbee': 'アルティメットフリスビー',
    'Unified Communications': '統合コミュニケーション',
    Universities: '大学',
    'Usability Testing': 'ユーザビリティテスト',
    'Vacation Rental': 'バケーションレンタル',
    'Vending and Concessions': '販売と譲歩',
    'Venture Capital': 'ベンチャーキャピタル',
    'Vertical Search': '垂直検索',
    Veterinary: '獣医',
    'Video Advertising': 'ビデオ広告',
    'Video Chat': 'ビデオチャット',
    'Video Conferencing': 'ビデオ会議',
    'Video Editing': 'ビデオ編集',
    'Video Games': 'ビデオゲーム',
    'Video Streaming': 'ビデオストリーミング',
    'Video on Demand': 'ビデオオンデマンド',
    'Virtual Assistant': 'バーチャルアシスタント',
    'Virtual Currency': '仮想通貨',
    'Virtual Desktop': '仮想デスクトップ',
    'Virtual Goods': '仮想商品',
    'Virtual Reality': '仮想現実',
    'Virtual Workforce': 'バーチャルワークフォース',
    'Virtual World': 'バーチャルワールド',
    Virtualization: '仮想化',
    'Visual Search': 'ビジュアル検索',
    VoIP: 'VoIP',
    'Vocational Education': '職業教育',
    'Volley Ball': 'バレーボール',
    Warehousing: '倉庫業',
    'Waste Management': '廃棄物管理',
    Water: '水',
    'Water Purification': '水の浄化',
    'Water Transportation': '水上輸送',
    'Wealth Management': '資産管理',
    Wearables: 'ウェアラブル',
    'Web Apps': 'ウェブアプリ',
    'Web Browsers': 'ウェブブラウザ',
    'Web Design': 'ウェブデザイン',
    'Web Development': 'ウェブ開発',
    'Web Hosting': 'ウェブホスティング',
    WebOS: 'WebOS',
    Wedding: '結婚式',
    Wellness: '健康',
    Wholesale: '卸売',
    'Wind Energy': '風力エネルギー',
    Windows: 'ウィンドウズ',
    'Windows Phone': 'ウィンドウズフォン',
    'Wine And Spirits': 'ワインとスピリッツ',
    Winery: 'ワイナリー',
    'Wired Telecommunications': '有線通信',
    Wireless: 'ワイヤレス',
    "Women's": 'ウィメンズ',
    'Wood Processing': '木材加工',
    Xbox: 'エックスボックス',
    'Young Adults': '若者',
    association: '協会',
    eSports: 'eスポーツ',
    iOS: 'iOS',
    mHealth: 'モバイルヘルス',
    macOS: 'macOS',
    nonprofit: '非営利',
  },
}
